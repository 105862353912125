import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  // Added by:- Suman sahu  Date:- 26/03/2023
  // Reason:- add link for "powered by adyant"
  const opeenSiteOfAdyant = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // End of added code

  return (
    <>
      <div className="footer_section">
        <h5>Copyright © 2023 Employee Management</h5>
        <Link
          className="adyant_footer_link"
          onClick={() => opeenSiteOfAdyant("https://adyant.co.in/")}
        >
          <h6 className="adyant">Powered by Adyant</h6>
        </Link>
      </div>
    </>
  );
};

export default Footer;
