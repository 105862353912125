//  Added by Suman
//  Reason - for enter work detail of users

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dsrStyles from "./dsr.module.css";
import {
  GetSavedWorkApi,
  GetUserProjectAndPositionApi,
  PostUserWorkApi,
  DeleteRowApi,
  submitWorkDetailApi,
} from "../api/service";
// import { Notyf } from 'notyf';
import "notyf/notyf.min.css";
import { message } from "antd";

const DSR = () => {
  const navigate = useNavigate();

  // Added by Suman Date- 23/01/2023
  // Reason - useState for get position and project data on dashboard

  const [position, setPosition] = useState([]);
  const [project, setProject] = useState([]);

  // end of added code

  // Added by :-  Parineeta gedam
  // Date- 27-03-2023
  // Reason - useState for get work hour, using this map the data in actual hour.

  const [workHour, setWorkHour] = useState("");

  // end of added code 27-03-2023

  var [formIndex, setFormIndex] = useState(0);

  //   Added by - Suman, Date - 25-01-2023
  //   Reason - for show notification after submit work detail of the user

  // commented by:- Suman Date:- 18/02/2023

  // const notyf = new Notyf({
  //   duration: 3000,
  //   position: {
  //     x: 'center',
  //     y: 'top',
  //   }
  // });

  // End of commented code
  //  End of added code

  //  Added by Suman Date-19/1/2023
  //  Reason - Add icon for generate one more row
  const [inputList, setInputList] = useState([
    // {
    //   checkbox: false,
    //   date: "",
    //   position_id: "",
    //   project_id: "",
    //   task_id: "",
    //   description: "",
    //   actual_hour: "",
    //   billable_hour: "",
    // }
  ]);

  // const [deleteRow, setDeleteRow] = useState(false)

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...inputList];
  //   list[index][name] = value;
  //   setInputList(list);
  // };

  // Added by:- Suman Date:-05/02/2023
  // Reason:- remove added row according to need

  // const handleRemoveRow = (index) => {
  //   const list = [...inputList];
  //   list.splice(index, 1);
  //   setInputList(list);
  // };

  // End of added code

  const handleAddForm = () => {
    setFormIndex(++formIndex);
    setInputList([
      ...inputList,
      {
        checkbox: false,
        sno: formIndex,
        id: "",
        date: "",
        position_id: "",
        project_id: "",
        task_id: "",
        description: "",
        actual_hour: "",
        billable_hour: "",
      },
    ]);
  };
  // end of added code

  useEffect(() => {
    getUserProjectAndPosition();
    getSavedWorkData();
  }, []);

  // Reason:-  get and show position and project data on work entry
  const getUserProjectAndPosition = async () => {
    const response = await GetUserProjectAndPositionApi(
      window.localStorage.getItem("token")
    );
    if (response) {
      // Added by Suman Date- 23/01/2023
      // Reason - for setPosition and setProject data
      setPosition(response.positionData);
      setProject(response.projectData);
      // end of added code

      // Added by :- Parineeta Gedam
      // Date- 27-03-2023
      // Reason - for work hour.
      setWorkHour(response.workHour);
      // end of added code 27-03-2023
    }
  };

  const getSavedWorkData = async () => {
    let itr = 0;
    let initialInputFields = [];
    var token = localStorage.getItem("token");
    const response = await GetSavedWorkApi(token);
    if (response.length > 0) {
      // Commented by:- Suman sahu  Date:- 22/03/2023
      // Reason:- Didn't work properly for get saved work data and show on ui

      // for (var i = 0; i < response.length; i++) {
      //   setInputList([
      //     ...inputList,
      //     {
      //       checkbox: response[i]["checkbox"],
      //       date: response[i]["date"],
      //       position_id: response[i]["position_id"],
      //       project_id: response[i]["project_name"],
      //       task_id: response[i]["task_id"],
      //       description: response[i]["description"],
      //       actual_hour: response[i]["actual_hour"],
      //       billable_hour: response[i]["billable_hour"],
      //       work_type: response[i]["work_type"],
      //     },
      //   ]);
      // }
      // setSavedWork(response)

      // End of commented code

      // Added by:- Suman sahu  Date:- 22/03/2023
      // Reason:- For get saved work data and show on ui

      response.forEach((e) => {
        itr++;
        initialInputFields.push({
          checkbox: "",
          sno: itr,
          id: e.id,
          date: e.date,
          position_id: e.position_id,
          project_id: e.project_id,
          task_id: e.task_id,
          description: e.description,
          actual_hour: e.actual_hour,
          billable_hour: e.billable_hour,
          work_type: e.work_type,
        });
      });
      setInputList(...inputList, initialInputFields);
      setFormIndex(itr);
    } else {
      setInputList([
        ...inputList,
        {
          checkbox: "",
          sno: formIndex,
          id: "",
          date: "",
          position_id: "",
          project_id: "",
          task_id: "",
          description: "",
          actual_hour: "",
          billable_hour: "",
          work_type: "",
        },
      ]);
    }
  };

  // End of added code

  let i = 0;
  function formget() {
    for (i = 0; i < inputList.length; i++) {
      const form = document.getElementById(`form${i}`).elements;
    }
  }

  // Added by:-  Suman  Date:- 22/02/2023
  // Reason :-  validation for date, user can enter only present and past date not  future date
  var todaydate = new Date();
  var month = todaydate.getMonth() + 1;
  var year = todaydate.getUTCFullYear();
  var tdate = todaydate.getDate() - 1;
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  const maxDate = year + "-" + month + "-" + tdate;
  // End of added code

  // Added by:- Suman sahu  Date:- 11/03/2023
  // Reason:- validation for up to one year ago from the current date

  var todaydate = new Date();
  var month = todaydate.getMonth() + 1;
  var year = todaydate.getUTCFullYear() - 1;
  var tdate = todaydate.getDate() - 1;
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  const minDate = year + "-" + month + "-" + tdate;
  // End of added code

  // Reason:- when click on submit button this function will be call
  const handleSaveWorkDetails = (e) => {
    e.preventDefault();
    var userWorkEntries = [];

    for (i = 0; i < inputList.length; i++) {
      const form = document.getElementById(`form` + inputList[i].sno);
      var userWorkData = {
        id: form[1].value,
        date: form[2].value,
        position_id: form[3].value,
        project_id: form[4].value,
        task_id: form[5].value,
        description: form[6].value,
        actual_hour: form[7].value,
        billable_hour: form[8].value,
        work_type: form[9].value,
      };
      // alert(JSON.stringify(userWorkData))
      // Added by:- Suman   Date:-05/02/2023
      // Reason:- for add validation
      if (userWorkData.date === "") {
        document.getElementById("form" + i + "_date1").innerText =
          "Please enter date";
        return false;
      } else {
        document.getElementById("form" + i + "_date1").innerText = "";
      }

      if (userWorkData.description === "") {
        document.getElementById("form" + i + "_description1").innerText =
          "Please enter description";
        return false;
      }
      if (userWorkData.description.length > 255) {
        document.getElementById("form" + i + "_description1").innerText =
          "Please accept 255 digits only";
        return false;
      } else {
        document.getElementById("form" + i + "_description1").innerText = "";
      }

      if (userWorkData.billable_hour === "") {
        document.getElementById("form" + i + "_billable_hour1").innerText =
          "Please enter billable hour";
        return false;
      } else {
        document.getElementById("form" + i + "_billable_hour1").innerText = "";
      }

      // End of added code

      userWorkEntries.push(userWorkData);
    }

    submitWork(userWorkEntries);
  };

  // Reason:- when click on submit button, hit this API and users work data will be saved
  const submitWork = async (userWorkEntries) => {
    var token = localStorage.getItem("token");
    const response = await PostUserWorkApi(userWorkEntries, token);
    if (response["success"]) {
      userWorkEntries = [];

      // commented by:- Suman  Date:- 18/02/2023
      // notyf.success(response["success"]);
      // End of commented code

      // Modified by: Suman  Date:- 18/02/2023
      // Reason:- replace notification msg notyf to antd due to repeat error msg line by line
      message.success({
        content: response["success"],
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
      // End of modified code

      navigate("/dashboard");
    } else {
      // notyf.error("Retry!!!!!!!!!!");
    }
  };

  // Added by:- Suman sahu
  // Reason:- Save work data on localStorage

  // const saveWorkDetails = (e) =>{
  //   e.preventDefault();
  //   var userWorkEntries = [];

  //   for (i = 0; i < inputList.length; i++) {
  //     const form = document.getElementById(`form${i}`).elements
  //     var userWorkData = {
  //       date: form[0].value,
  //       position_id: form[1].value,
  //       project_id: form[2].value,
  //       actual_hour: form[3].value,
  //       billable_hour: form[4].value,
  //       description: form[5].value,
  //       work_type: form[6].value
  //     }
  //     userWorkEntries.push(userWorkData)
  //   }
  //   setWorkData(userWorkEntries)
  // }

  // const handleSaveWork = (e) => {
  //   saveWorkDetails(e);
  //   localStorage.setItem('workData', JSON.stringify(workData));
  //   const data = localStorage.getItem('workData')
  // }

  // End of added code

  // Added by:- Suman sahu  Date:- 02/03/2023
  // Reason:- for if user select check box and click delete button then deleted multiple rows

  // Commented by:- Suman sahu  Date:- 22/03/2023
  // Reason:- Didn't work properly for delete row

  // const deleteSelectedRows = () => {
  //   var deleteToRows = [];

  //   for (i = 0; i < inputList.length; i++) {
  //     const form = document.getElementById(`form${i}`);
  //     if (form[0].checked === true) {
  //       deleteToRows.push(i);
  //     }
  //   }

  //   for (i = 0; i < deleteToRows.length; i++) {
  //     document.getElementById(`form` + deleteToRows[i]).remove();
  //     inputList.splice(deleteToRows[i], 1);
  //   }
  // };

  // End of commented code
  // End of added code

  // Added by:- Suman sahu  Date:- 22/03/2023
  // Reason:- For detete selected row

  function handleDeleteForm(e) {
    let copiedInputFields = [];
    var backendIdsToDelete = [];
    inputList.filter((node) => {
      var row = document.getElementById(`form` + node.sno);
      if (row["checkbox"].checked) {
        // Added by:- Suman sahu  Date:- 24/03/2023
        // Reason:- For delete selected row data on the backend
        backendIdsToDelete.push(row["id"].value);
        // End of added code

        // Added by:- Suman sahu  Date:- 26/03/2023
        // Reason:- when click on delete button without select any checkbox thais message will be display
        message.success({
          content: "Successfully deleted",
          key: 1,
          style: {
            marginTop: "9vh",
          },
        });
        // End of added code

        return false;
      } else {
        // Added by:- Suman sahu  Date:- 26/03/2023
        // Reason:- when click on delete button without select any checkbox thais message will be display
        message.error({
          content: "Please select altleast one checkbox",
          key: 1,
          style: {
            marginTop: "9vh",
          },
        });
        // End of added code

        copiedInputFields.push(node);
        return true;
      }
    });
    setInputList(copiedInputFields);
    deleteRow(backendIdsToDelete);
  }

  //  Added by:- Suman  Data:- 24/03/2023
  //  Reason:- For delete selected row data on the backend

  const deleteRow = async (deletedId) => {
    const response = await DeleteRowApi(deletedId);
  };

  // End of added code

  // End of added code

  //  Added by:- Suman  Data:- 25/03/2023
  //  Reason:- For reset current rows

  const resetInput = () => {
    for (var j in inputList) {
      var row = document.getElementById(`form` + inputList[j].sno);
      if (row["id"].value == "") {
        row["date"].value = "";
        row["position_id"].value = "Manager";
        row["project_id"].value = "other";
        row["task_id"].value = "";
        row["description"].value = "";
        row["billable_hour"].value = "";
      }
    }
  };
  // End of added cod

  // Added by:- Suman sahu  Date:- 02/04/2023
  // Reason:- When user click on submit button thin function will be call and saved data in the backend

  const handleFormSubmit = (e) => {
    e.preventDefault();
    var userWork = [];

    for (i = 0; i < inputList.length; i++) {
      const form = document.getElementById(`form` + inputList[i].sno);
      var userWorkData = {
        id: form[1].value,
        date: form[2].value,
        position_id: form[3].value,
        project_id: form[4].value,
        task_id: form[5].value,
        description: form[6].value,
        actual_hour: form[7].value,
        billable_hour: form[8].value,
        work_type: form[9].value,
      };
      userWork.push(userWorkData);
    }
    submitForm(userWork);
  };

  const submitForm = async (userWork) => {
    var token = localStorage.getItem("token");
    const response = await submitWorkDetailApi(userWork, token);
    if (response["success"]) {
      userWork = [];
      message.success({
        content: response["success"],
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });

      navigate("/dashboard");
    } else {
      message.error({
        content: "Please save all data before submit",
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
    }
  };

  // End of added code

  return (
    <>
      <div className={dsrStyles.center} id="dashboard_maindiv">
        <h1 className={dsrStyles.work_entry_heading}>DSR</h1>
        <form
          className={dsrStyles.weekly_report_form}
          onSubmit={handleFormSubmit}
        >
          <div className={dsrStyles.add_button_div}>
            <button
              className={dsrStyles.add_btn}
              type="button"
              onClick={handleAddForm}
            >
              Add row
            </button>
          </div>
          <div className={dsrStyles.container}>
            {/* Added by:- Suman sahu Date:- 16/03/2023
                Reason:- for show only one label of every dsr columns */}
            <div className={dsrStyles.profile_outer_lables}>
              <p className={dsrStyles.Date_input_label}>Date</p>
              <p className={dsrStyles.position_input_label}>Position</p>
              <p className={dsrStyles.project_input_label}>Project</p>
              <p className={dsrStyles.task_id_input_label}>Task Id</p>
              <p className={dsrStyles.description_input_label}>Description</p>
              <p className={dsrStyles.actual_hour_input_label}>Actual Hour</p>
              <p className={dsrStyles.billable_hour_input_label}>
                Billable Hour
              </p>
            </div>
            {/* End of added code */}

            {inputList.map((data, i) => {
              return (
                <form id={`form` + data.sno} key={`form` + data.sno}>
                  <div className={dsrStyles.column}>
                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.checkbox_label}>Select</p>
                      <input
                        className={dsrStyles.checkbox}
                        type="checkbox"
                        name={"checkbox"}
                      />
                    </div>

                    <div>
                      <input
                        name={"id"}
                        type="number"
                        id={"id"}
                        hidden
                        defaultValue={data.id ? data.id : null}
                      />
                    </div>

                    <div className={dsrStyles.input_row} id="work_data">
                      <p className={dsrStyles.inner_input_label}>Date</p>
                      <input
                        className={dsrStyles.date_input}
                        name={"date"}
                        type="date"
                        id={"date"}
                        defaultValue={data.date ? data.date : null}
                        placeholder="date"
                        // onChange={(e) => handleInputChange(e, i)}
                        min={minDate}
                        max={maxDate}
                      />
                      <p
                        id={"form" + i + "_date1"}
                        className={dsrStyles.dashboard_errormsg}
                      ></p>
                    </div>

                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>Position</p>
                      <select
                        className={dsrStyles.position_input}
                        name={"position_id"}
                        id={"position_id"}
                        // onChange={(e) => handleInputChange(e, i)}
                        defaultValue={
                          data.position_id ? data.position_id : null
                        }
                      >
                        {position.map((getPosition) => (
                          <>
                            <option>{getPosition.position_id}</option>
                          </>
                        ))}
                      </select>
                    </div>

                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>Project</p>
                      <select
                        className={dsrStyles.project_input}
                        name={"project_id"}
                        id={"project_id"}
                        // onChange={(e) => handleInputChange(e, i)}
                        defaultValue={data.project_id ? data.project_id : null}
                      >
                        {/* Added by Suman Date-28/01/2023
                                Reason - for add other option on project field */}
                        <option value="other">Other</option>
                        {/* end of added code */}
                        {project.map((getProject) => (
                          <option>{getProject.project_name}</option>
                        ))}
                      </select>
                    </div>

                    {/* Added by:- Suman  Date:- 28/02/2023 */}
                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>Task Id</p>
                      <input
                        className={dsrStyles.taskid_input}
                        name={"task_id"}
                        id={"task_id"}
                        placeholder="Task Id"
                        defaultValue={data.task_id ? data.task_id : null}
                      />
                      {/* <p id={"form" + i + "_tast_id1"} ></p> */}
                    </div>

                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>Description</p>
                      <textarea
                        className={dsrStyles.description_input}
                        name={"description"}
                        id={"description"}
                        placeholder="Description:-"
                        maxLength="255"
                        defaultValue={
                          data.description ? data.description : null
                        }
                      ></textarea>
                      <p
                        id={"form" + i + "_description1"}
                        className={dsrStyles.dashboard_errormsg}
                      ></p>
                    </div>

                    {/* End of added code */}

                    {/*  Modify by:- Suman 
                            Date:- 02/02/2023 
                            Reason :- add proper name */}
                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>Actual hour</p>
                      <input
                        className={dsrStyles.actual_hour_input}
                        name={"actual_hour"}
                        id={"actual_hour"}
                        disabled
                        // value={8}
                        defaultValue={
                          workHour.work_hour ? workHour.work_hour : null
                        }
                        // onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>

                    <div className={dsrStyles.input_row}>
                      <p className={dsrStyles.inner_input_label}>
                        Billable Hour
                      </p>
                      <input
                        className={dsrStyles.billable_hour_input}
                        name={"billable_hour"}
                        type="number"
                        id={"billable_hour"}
                        placeholder="Working hour"
                        // onChange={(e) => handleInputChange(e, i)}
                        max={24}
                        min={1}
                        defaultValue={
                          data.billable_hour ? data.billable_hour : null
                        }
                      />
                      <p
                        id={"form" + i + "_billable_hour1"}
                        className={dsrStyles.dashboard_errormsg}
                      ></p>
                    </div>
                    {/* End of modified code */}

                    <div className={dsrStyles.input_row_work_type}>
                      <input
                        name={"work_type"}
                        hidden
                        type="text"
                        id={"work_type"}
                        // onChange={(e) => handleInputChange(e, i)}
                        defaultValue={data.work_type ? data.work_type : null}
                      />
                    </div>
                  </div>

                  {/* Added by Suman Date-28/01/2023 */}

                  {/* Commented by:-Suman  Date:- 28/02/2023
                    Reason:- fix this field in same row */}

                  {/* <div className={dsrStyles.description_div}>
                      <textarea className={dsrStyles.description_area} name={"description" + i} id={"description" + i} rows="8" cols="50" placeholder='Description:-' maxLength='500'></textarea>
                    </div>
                    <p id={"form" + i + "_description"} className={dsrStyles.dashboard_errormsg}></p> */}

                  {/* End of commented code */}

                  {/* {inputList.length !== 1 &&
                      <button className={dsrStyles.add_btn} type='button' onClick={() => handleRemoveRow(1)}>Remove</button>
                    } */}
                  {/* {inputList.length - 1 === i &&
                      <button className={dsrStyles.add_btn} type='button' onClick={handleAddForm}>Add row</button>
                    } */}

                  {/* end of added code */}
                </form>
              );
            })}
          </div>

          <div className={dsrStyles.button_div}>
            <button
              className={dsrStyles.dsr_btn}
              onClick={handleSaveWorkDetails}
            >
              Save
            </button>

            <button className={dsrStyles.dsr_btn} type="submit">
              Submit
            </button>
            <button
              className={dsrStyles.dsr_btn}
              type="button"
              onClick={handleDeleteForm}
              //  onClick={() =>{ setDeleteRow(deleteRow==true?handleRemoveRow():"")}}
            >
              Delete
            </button>
            <button
              className={dsrStyles.dsr_btn}
              type="button"
              onClick={resetInput}
            >
              Reset
            </button>
          </div>

          {/* <button className={dsrStyles.button}
              // onClick={(e) => handleSaveWork(e)} 
              onClick={() => navigate("/")}
              type='button'>Cancel</button> */}
        </form>
      </div>
    </>
  );
};

export default DSR;

// end of added code
