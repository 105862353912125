// Added by:- Suman  Date:- 09/02/2023
// Reason:- for work on forgot password functionality

import { React, useState } from "react";
import "antd/dist/reset.css";
import "./forgotPassword.css";
import { Button, Form, Input, message, Modal } from "antd";
import { sendOtpMailApi, updatePasswordApi } from "../api/service";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 19,
  },
};

const layout2 = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 19,
  },
};

// const validateMessages = {
//     required: "${label} is required",
//     types: {
//         email: "${label} is not a valid email",
//         number: "${label} is not a valid number"
//     },
//     number: {
//         range: "${label} must be between ${min} and ${max}"
//     }
// };

const ForgotPassword = (props) => {
  const { form } = Form.useForm();
  const { formdata } = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mail, setMail] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisable2, setBtnDisable2] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleUpdateModalCancel = (props) => {
    setIsModalVisible(false);
    // props.closeModal()
  };

  const handleSubmitModalCancel = () => {
    props.otpModalClose(false);
    // setIsModalVisible(false);
  };

  const onFinish = async (value) => {
    setBtnDisable(true);
    const email = {
      email: value.email.toLowerCase(),
    };
    const response = await sendOtpMailApi(email);

    if (response.msg) {
      setBtnDisable(false);
      message.success({
        content: response.msg,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
      handleSubmitModalCancel();
      setMail(response.mail);
      showModal();

      // Modified by:- Suman sahu  Date:- 27/03/2023
      // Reason:- For reset input fields after submitted form

      // form.resetFields();
      document.getElementById("remove_data").reset();

      // End of modified code 27/03/2023
    }
    if (response.error) {
      message.error({
        content: response.error,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
      setBtnDisable(false);
    }
  };

  const onFinish2 = async (value) => {
    setBtnDisable2(true);
    const updateData = {
      email: mail,
      otp: value.otp,
      password: value.password,
    };
    const response = await updatePasswordApi(updateData);
    if (response.msg) {
      message.success({
        content: response.msg,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
      handleUpdateModalCancel();

      // Modified by:- Suman sahu  Date:- 27/03/2023
      // Reason:- For reset input fields after submitted form

      // formdata.resetFields();
      document.getElementById("remove_otptable_data").reset();

      // End of modified code 27/03/2023
    }
    if (response.error) {
      message.error({
        content: response.error,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });

      setBtnDisable2(false);
    }
  };

  // Added by:- Suman sahu  Date:- 11/03/2023
  // Reason:- For show validation after focusout input field
  const focusOutInputError = async (currentElement) => {
    try {
      const values = await form.validateFields([currentElement]);
      //   console.log('Success:', values);
    } catch (errorInfo) {
      //   console.log('Failed:', errorInfo);
    }
  };
  // End of added code

  return (
    <div className="main_forgetpassword">
      <Modal
        title="Create new password"
        open={props.otpModalOpen}
        onCancel={handleSubmitModalCancel}
        footer={null}
      >
        <Form
          style={{ width: "90%" }}
          form={form}
          {...layout}
          id="remove_data"
          name="nest-messages"
          onFinish={onFinish}
          // validateMessages={validateMessages}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="email"
            label="Email"
            validateTrigger={["onBlur"]}
            rules={[
              {
                type: "email",
                message: "Please enter valid email",
              },
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input
              id="email"
              placeholder="Please enter valid e-mail"
              style={{ width: "100%" }}
              onBlur={() => {
                focusOutInputError("email");
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button
              htmlType="submit"
              disabled={btnDisable ? true : false}
              className="forgetpass_submit"
            >
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Create new password"
        open={isModalVisible}
        onOk={handleUpdateModalCancel}
        onCancel={handleUpdateModalCancel}
        footer={null}
      >
        {isModalVisible ? (
          <Form
            form={formdata}
            {...layout2}
            name="form"
            id="remove_otptable_data"
            onFinish={onFinish2}
            // validateMessages={validateMessages}
          >
            <Form.Item
              name="otp"
              label="Otp"
              rules={[
                {
                  required: true,
                  message: "Enter your OTP",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (isNaN(value)) {
                      return Promise.reject("OTP has to be a number.");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder="Enter otp" maxLength={4} />
            </Form.Item>

            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Enter password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (/^\S{1,}$/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Password should contain only alphabets(a-z) or digits(0-9)"
                      )
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter new password" maxLength={15} />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error("Password mismatch"));
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button
                className="update_btn"
                htmlType="submit"
                disabled={btnDisable2 ? true : false}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </div>
  );
};

export default ForgotPassword;

// End of added code
