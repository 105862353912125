// Added by -  Parineeta Gedam
// Date :- 10-03-2023
// Reason :- From this user can see our all leave type.

import "./TimeOff.css";
import React, { useState, useEffect } from "react";
import { getLeavesTypeApi } from "../api/DynamicApi";
import config from "../api/config";
import ApplyForLeaveForm from "./ApplyForLeaveForm";

export default function TimeOff() {
  // Added by Parineeta
  // Date :- 20-1-2023
  // Reason :- Using this we will map the data in type_of_leave.

  const [leaveType, setLeaveType] = useState([]);

  // End of added code

  // Added by Parineeta
  // Date :- 20-1-2023
  // Reason :- Whatever user login and by admin whatever leave he/she have , Using this method we will getting the type_of_leave.

  useEffect(() => {
    getLeaveType();
  }, []);

  const getLeaveType = async () => {
    const response = await getLeavesTypeApi(
      window.localStorage.getItem("token")
    );
    if (response) {
      setLeaveType(response);
    }
  };

  // End of added code 20-01-2023

  // Added by - Parineeta
  // Date - 24-02-2023
  // Reason - In type-of-leave remove the "Leave Without pay" leave.

  var totalLeavetypes = [];
  for (var i = 0; i < leaveType.length; i++) {
    if (leaveType[i].type_of_leave === "Leave Without Pay") {
    } else {
      totalLeavetypes.push(leaveType[i]);
    }
  }
  // End of added code

  return (
    <>
      {/* Added by -  Parineeta Gedam
      Date :- 10-03-2023
      Reason :- From this user can see our all leave type. */}
      <div className="time-off-parent">
        <div className="time-off-heading-container">
          <h1 className="heading">Apply for leave</h1>
        </div>
        <div className="time-off-second-parent">
          <div className="time-off-col1">
            {totalLeavetypes.map((item, i) => (
              <div
                className="time-off-type-of-leave-container"
                style={{
                  background:
                    i % 2 === 0
                      ? "var(--timeOff-typeOfLeave-container-secondary-odd-backgroundColor)"
                      : "var(--timeOff-typeOfLeave-container-secondary-even-backgroundColor)",
                }}
              >
                <div className="type_of_leave_image_container">
                  <img
                    className="time-off-leave-type-img"
                    src={config.apiBaseURL + "/media/" + item.image}
                    alt="no img"
                  />
                </div>

                <div className="time-off-type-of-leave-data-container">
                  <h1 className="time-off-type-of-leave-label">
                    {item.type_of_leave}
                  </h1>
                  <h1 className="time-off-type-of-leave-day"> {item.day}</h1>
                </div>
              </div>
            ))}
          </div>
          {/* End of added code 10-03-2023*/}

          {/* Added by -  Parineeta Gedam
              Date :- 10-03-2023
              Reason :- From this user can apply for leave. */}
          <div className="time-off-col2">
            <ApplyForLeaveForm />
          </div>
          {/* End of added code 10-03-2023*/}

        </div>
      </div>
    </>
  );
}
// End of added code 10-03-2023
