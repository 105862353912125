import React, { useState, createContext, useContext, useEffect } from "react";

const cart = createContext();

const Context = ({ children }) => {
  //   Added by - Parineeta
  //   Date :- 19-1-2023
  //   Reason - if user login then the user detail store in the localStorage

  const [ProfileData, setProfileData] = useState(null);

  useEffect(() => {
    if (typeof localStorage.getItem("profileData") != "undefined")
      setProfileData(JSON.parse(localStorage.getItem("profileData")));
  }, []);

  // End of added code

  return (
    <>
      <cart.Provider value={{ ProfileData, setProfileData }}>
        {children}
      </cart.Provider>
    </>
  );
};

export const Appstate = () => {
  return useContext(cart);
};

export default Context;
