import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import profile from "../images/profile.png";
// import OutsideClickHandler from "react-outside-click-handler";
import { Appstate } from "./Context";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import "antd/dist/reset.css";
import { Popconfirm, message } from "antd";
import adyant from "../images/adyantLogo.png";

const Header = ({ setIsOpen, isOpen }) => {
  const { ProfileData } = Appstate();

  const navigate = useNavigate();

  //   Added by - Suman, Date - 03-03-2023
  //   Reason - for show notification after logout the user

  // commented by:- Suman  Date:- 18/02/2023

  // const notyf = new Notyf({
  //   duration: 3000,
  //   position: {
  //     x: "center",
  //     y: "top",
  //   },
  // });

  // End of commented code
  //  End of added code

  useEffect(() => {}, [ProfileData]);

  function menuOpen() {
    setIsOpen(true);
  }

  function meneClose() {
    setIsOpen(false);
  }

  // function logOut(props) {
  //   localStorage.clear();
  //     props.history.push("/login");
  //       notyf.success("successfully logged out");
  // }

  // Added by:- Suman   Date:- 08/02/2023
  // Reason:-  add popup for logout button

  const confirm = (props) => {
    localStorage.clear();

    // Modified by:- Suman  Date:- 18/02/2023

    message.success({
      content: "Successfully logged out",
      key: 1,
      style: {
        marginTop: "9vh",
      },
    });
    // notyf.success("<span> You are logged out </span>");

    // End of modified code
    navigate("/login");
  };

  const cancel = (e) => {};

  // End of added code

  return (
    <>
      <div className="head">
        <div className="header">
          <input type="checkbox" id="check" />
          <label htmlFor="check" className="checkbtn">
            <i
              onClick={() => {
                setIsOpen(isOpen == true ? false : true);
              }}
            >
              ...
            </i>
          </label>
          <label className="logo">
            <Link className="link" to="/" onClick={meneClose}>
              <img src={adyant} className="header_adyant_logo" alt="adyant" />
              {/* Adyant */}
            </Link>
          </label>
          {/* <OutsideClickHandler> */}
          <ul className={isOpen == true ? "menu-ul" : "menu-ul-open"}>
            {/* Added by:- Suman sahu  Date:- 18/03/2023
                  Reason:- Before login home page will be displayed first time &
                  After login dashboard page will be displayed first time*/}

            {localStorage.getItem("token") ? (
              <>
                <li>
                  <Link
                    className="header_link"
                    to="/ "
                    onClick={meneClose}
                  >
                    Home
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link className="header_link" to="/" onClick={meneClose}>
                  Home
                </Link>
              </li>
            )}
            {/* End of added code */}

            {/* Commented by:- Suman sahu  Date:- 19/03/2023*/}
            {/* <li>
               Modified by:- Suman sahu  Date:- 18/03/2023
                  Reason:- Before login home page will be displayed first time

               <Link className="header_link" to="/" onClick={meneClose}>
                Dashboard
              </Link>

              <Link className="header_link" to="/" onClick={meneClose}>
                Home
              </Link>
               End of added code 
            </li> */}
            {/* End of commented code */}

            {/* <li>
              <Link className="header_link" to="/about" onClick={meneClose}>
                About
              </Link>
            </li>
            <li>
              <Link className="header_link" to="/contact" onClick={meneClose}>
                Contact
              </Link>
            </li> */}

            <li>
              <a href="https://adyant.co.in/" target="_blank" className="header_link">
                About
              </a>
            </li>
            <li>
              <a href="https://adyant.co.in/contact-us-india-1" target="_blank" className="header_link">
                Contact
              </a>
            </li>

            {localStorage.getItem("token") ? (
              <>
                {/* Added by:- Suman sahu  Date:- 18/03/2023
                    Reason:- After login dashboard page will be displayed first time */}

                {/* Commented by:- Suman sahu  Date:- 19/03/2023 */}

                {/* <li>
                  <Link
                    className="header_link"
                    to="/dashboard"
                    onClick={meneClose}
                  >
                    Dashboard
                  </Link>
                </li> */}

                {/* End of commented code */}
                {/* End of added code */}

                <li>
                  {/* Commented by - Suman 
                             Reason - for dropdown on work link */}
                  {/* <Link className="link" to="/dsr" onClick={meneClose}>
                      DSR
                    </Link> */}
                  {/* end of added code */}

                  {/* Added by - Suman 
                             Reason - for dropdown on work link */}

                  {/* Commented by:- Suman   Date:- 19/02/2023 */}

                  {/* <Popup
                      on={"hover"}
                      trigger={<Link className="link"> Work</Link>}
                      position="bottom left"
                    >
                      <Link className="work_dropdown" to="/dsr">
                        <h3>Work Entry</h3>
                      </Link>
                      <hr></hr>
                      <Link className="work_dropdown" to="/userWork">
                        <h3>Work Summary</h3>
                      </Link>
                    </Popup> */}

                  {/* End of commented code */}
                  {/* end of added code */}
                </li>

                {/* Added by:- Suman  Date:- 19/02/2023
                    Reason:- add dropdown option on Work */}
                <div className="work_dropdown_div">
                  <button className="work_button">Works</button>
                  <div className="workdropdown">
                    <Link to="/dsr" className="work_option" onClick={meneClose}>
                      <h3>DSR</h3>
                    </Link>
                    <Link
                      to="/work_summary"
                      className="work_option"
                      onClick={meneClose}
                    >
                      <h3>Work Summary</h3>
                    </Link>
                  </div>
                </div>
                {/* End of added code */}

                {/* Added by :- Parineeta
                      Date :- 18-1-2023
                      Reason :- When user login show the Leave details
                  */}

                {/* Commented By - Parineeta
                      Date :- 15-2-2023 */}

                {/* <li>
                    <Link
                      className="link"
                      to="/apply_for_leave"
                      onClick={meneClose}
                    >
                      Leave
                    </Link>
                  </li>

                  <li>
                    <Link className="link" to="/all_leave" onClick={meneClose}>
                      All Leave
                    </Link>
                  </li> */}

                {/* end of commented code */}

                {/* commented by - Parineeta
                          Date - 22-02-2023 
                          Reason - changes in popup 
                      */}
                {/* Added by :- Parineeta
                      Date :- 15-02-2023
                      Reason :- When user login show the Leave details */}

                {/* <li>
                    <Popup
                      on={"hover"}
                      trigger={<Link className="link"> Leave</Link>}
                      position="bottom left"
                    >
                      <Link
                        className="work_dropdown"
                        to="/apply_for_leave"
                        onClick={meneClose}
                      >
                        <h3>Apply for leave</h3>
                      </Link>
                      <hr></hr>
                      <Link
                        className="work_dropdown"
                        to="/all_leave"
                        onClick={meneClose}
                      >
                        <h3>All leave</h3>
                      </Link>
                    </Popup>
                  </li> */}

                {/* end of added code */}
                {/* end of commented code */}

                {/* Added by:- Parineeta  Date:- 22/02/2023
                    Reason:- add dropdown option on leave */}
                <div className="work_dropdown_div">
                  <button className="work_button">Leave</button>
                  <div className="workdropdown">
                    <Link
                      className="work_option"
                      to="/TimeOff"
                      onClick={meneClose}
                    >
                      <h3>Time Off</h3>
                    </Link>

                    <Link
                      className="work_option"
                      to="/all_leave"
                      onClick={meneClose}
                    >
                      <h3>All leave</h3>
                    </Link>
                  </div>
                </div>
                {/* End of added code */}

                <li>
                  {/* <Link
                      className="link"
                      to="/login"
                      onClick={() => {
                        meneClose();
                        logOut();
                      }}
                    >
                      Logout
                    </Link> */}
                  <Popconfirm
                    className="logout_popup"
                    title="Are you sure you want to logout?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    cancelText="Cancel"
                    okText="Ok"
                  >
                    <a href="#" className="logout_link">
                      Logout
                    </a>
                  </Popconfirm>
                </li>
                <li>
                  <Link to="/profile">
                    <img
                      className="profile_logo"
                      src={profile}
                      onClick={meneClose}
                    />
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link className="header_link" to="/login" onClick={meneClose}>
                    Login
                  </Link>
                </li>
                <li>
                  <Link
                    className="header_link"
                    to="/registration"
                    onClick={meneClose}
                  >
                    Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>
          {/* </OutsideClickHandler> */}
        </div>
      </div>
    </>
  );
};

export default Header;
