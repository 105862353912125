import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import Login from "./pages/Login";
// import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Registration from "./pages/Registration";
import DSR from "./pages/DSR";
import Header from "./pages/Header";
import Profile from "./pages/Profile";
import appStyles from "./app.module.css";
// import ApplyForLeave from "./Leave/ApplyForLeave";
import AllLeave from "./Leave/AllLeave";
import ForgotPassword from "./pages/ForgotPassword";
import { useState } from "react";
import WorkSummary from "./pages/WorkSummary";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import TimeOff from "./Time Off/TimeOff";

function App() {
  // Added by:- Suman  Date:- 22/02/2023
  // Reason: Hide open menues on header in responsive
  const [isOpen, setIsOpen] = useState(false);
  // End of added code

  return (
    <>
      <Router>
        <Header setIsOpen={setIsOpen} isOpen={isOpen} />
        <div
          className={appStyles.bodyContainer}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Routes>
            {/*   Added by - Suman  */}
            {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/registration" element={<Registration />}></Route>
            <Route path="/dsr" element={<DSR />}></Route>
            <Route path="/Profile" element={<Profile />}></Route>
            {/* End of added code */}

            {/* Added by :- Parineeta Gedam
                            Date :- 18-1-2023
                            Reason :- 
                        */}
            {/* <Route path='/apply_for_leave' element={<ApplyForLeave />}></Route> */}

            {/* Modified by :- Parineeta Gedam
                            Date :- 03-02-2023
                            Reason :- Changes in ui and functionality.
                        */}

            <Route path="/TimeOff" element={<TimeOff />}></Route>

            {/* End of Modified code */}

            <Route path="/all_leave" element={<AllLeave />}></Route>
            {/* End of added code */}

            {/* Added by - Suman  Date-25/01/2023
                Reason - for see user work detail*/}
            <Route path="/work_summary" element={<WorkSummary />}></Route>
            {/* End of added code */}

            {/* Added by:- Suman  Date:-09/02/2023
                Reason:- for forgot password */}
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            {/* End of added code */}

            {/* Added by:- Suman sahu  Date:-18/03/2023
                Reason:- Before login home page will be displayed first time */}
            <Route path="/" element={<Home />}></Route>
            {/* End of added code */}
          </Routes>
        </div>
        {/* Added by:- Suman sahu  Date:-18/03/2023
            Reason:- Add footer */}
        <Footer />
        {/* End of added code */}
      </Router>
    </>
  );
}

export default App;
