// Added by Suman
// Reason - login page for registered user
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginStyle from "./login.module.css";
import { UserLoginPostApi } from "../api/service";
import adyant from "../images/adyant.png";
import { Appstate } from "./Context";
import "notyf/notyf.min.css";
import ForgotPassword from "./ForgotPassword";
import { message, Button, Form, Input } from "antd";

// Modified by:- Suman sahu  Date:- 27/02/2023
// Reason :-  change ui library
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const Login = () => {
  const [form] = Form.useForm();
  // Added by :- Parineeta
  const { setProfileData } = Appstate();
  //  end of added code

  const navigate = useNavigate();

  // Reason:- when click on submit button this function will be call
  const handleFormSubmit = async (e) => {
    var user = {
      email: e.email.toLowerCase(),
      password: e.password,
    };

    // validateAndSubmit(user);
    const response = await UserLoginPostApi(user);
    if (response["success"]) {
      localStorage.setItem("token", response.token);

      // Added by :- Parineeta
      localStorage.setItem("profileData", JSON.stringify(response.profileData));
      setProfileData(response.profileData);
      // end of added code

      localStorage.getItem("token");
      //   Reason - for show notification login the user
      var succesNotification = response["success"];
      // Reason:- replace notification msg notyf to antd due to repeat error msg line by line
      message.success({
        content: succesNotification,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });

      // modified by:- Suman sahu  Date:- 18/03/2023
      // Reason:- For open dashboard, after user have logged in

      navigate("/");

      // navigate("/dashboard");/

      // End of modified code
    } else {
      var errorNotification = response["error"];
      // Reason:- replace notification msg notyf to antd due to repeat error msg line by line
      message.error({
        content: errorNotification,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
    }
  };

  // Added by:- Suman  Date:- 09/02/2023
  // Reason:- Opan OTP modal for update password
  var [otpModalOpan, setOtpModalOpen] = useState(false);
  // End of added code

  // Added by:- Suman sahu  Date:- 11/03/2023
  // Reason:- For show validation after focusout input field
  const focusOutInputError = async (currentElement) => {
    try {
      const values = await form.validateFields([currentElement]);
      //   console.log('Success:', values);
    } catch (errorInfo) {
      //   console.log('Failed:', errorInfo);
    }
  };
  // End of added code

  return (
    <>
      <div className={loginStyle.login_section}>
        <div className={loginStyle.left_img_div}>
          <img src={adyant} className={loginStyle.img} alt="adyant" />
          {/* <i><h2 className={loginStyle.img_content}>"Welcome to Adyant"</h2></i> */}
          {/* <h3 className={loginStyle.login_content}>Don't have an Account ? </h3> */}
          <i>
            <h3 className={loginStyle.login_content}>
              To use all features of the application Log In and discover a great
              amount of new opportunities !!!{" "}
            </h3>
          </i>

          {/* <button className={loginStyle.btn_signup} onClick={() => navigate("/registration")} type='button'>Sign Up</button> */}
        </div>
        <div className={loginStyle.login_form_div}>
          <h1 className={loginStyle.login_heading}>Login</h1>
          <Form onFinish={handleFormSubmit} {...formItemLayout} form={form}>
            <div className={loginStyle.login_input_field}>
              <Form.Item
                name="email"
                label="E-mail"
                labelAlign="left"
                className={loginStyle.login_label}
                validateTrigger={["onBlur"]}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        if (
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:\.[a-zA-Z]+)*$/.test(
                            value
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter valid e-mail")
                        );
                      }
                      // else {
                      //   return Promise.reject(new Error("Please enter email "));
                      // }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Email"
                  className={loginStyle.input_box}
                  onBlur={() => {
                    focusOutInputError("email");
                  }}
                  // Added by:- Suman sahu  Date:- 27/03/2023
                  // Reason:- For disable paste events in this input field
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  // End of added code
                />
              </Form.Item>
            </div>

            <div className={loginStyle.login_input_field}>
              <Form.Item
                name="password"
                label="Password"
                labelAlign="left"
                className={loginStyle.login_label}
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className={loginStyle.input_box}
                  onBlur={() => {
                    focusOutInputError("password");
                  }}
                  // Added by:- Suman sahu  Date:- 27/03/2023
                  // Reason:- For disable paste events in this input field
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  // End of added code
                />
              </Form.Item>
            </div>

            <div className={loginStyle.login_forget_password}>
              <p onClick={() => setOtpModalOpen(true)}>Forgot Password?</p>
              <ForgotPassword
                otpModalOpen={otpModalOpan}
                otpModalClose={setOtpModalOpen}
              />
            </div>

            <Button htmlType="submit" className={loginStyle.login_btn}>
              Log In
            </Button>

            <div className={loginStyle.signup_link}>
              Don't have an account?
              <Link to={"/registration"}>SignUp</Link>
            </div>
          </Form>
          {/* End of added code */}
        </div>
      </div>
    </>
  );
};

export default Login;

// End of added code
