// Added by Parineeta
// date : 19-1-2223
// Reason : All Leave Detail page ... here if login user take any type of leave, then in this page show leves details but if login user is a manager then show the all user leaves

import React, { useState, useEffect } from "react";
import { message, Row, Select, Space } from "antd";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./AllLeave.css";
import { getUserLeavesApi } from "../api/DynamicApi";
import RequestedLeaveTabel from "./RequestedLeaveTabel";
import MyLeaveTabel from "./MyLeaveTable";
import config from "../api/config";
import Spinner from "../Spinner/Spinner";
import allImg1 from "../images/All.png";

// ------------- CREATE A OBJECT FOR MY LEAVE----------------------

// Added by :- Parineeta
// Date :- 01-02-2023
// Reason :- CREATE A OBJECT FOR MY LEAVE, we are storing conditions for filter logic.
var checkMyLeaveCondition = {
  all: true,
  today: false,
  thisWeek: false,
  thisMonth: false,
  thisYear: false,
  custom: false,
  allLeave: true,
};

// Reason - "All types pf leave" data come from backend and here, we will set the data with "False conditions".
var typeOfLeave = [];

// ---------------- END OF ADDED CODE -----------------------------------

// ------------- CREATE A OBJECT FOR REQUESTED LEAVE----------------------
// Added by :- Parineeta
// Date :- 01-02-2023
// Reason :- CREATE A OBJECT FOR REQUESTED LEAVE, we are storing conditions for filter logic.

var checkRequestedLeaveCondition = {
  all: true,
  today: false,
  thisWeek: false,
  thisMonth: false,
  thisYear: false,
  custom: false,
  allStatus: false,
  allLeave2: false,
};

// Reason - "All Leave Status" data come from backend and here, we will set the data with "False conditions".
var leaveStatus;

// Reason - "All types pf leave" data come from backend and here, we will set the data with "False conditions".
var typeOfLeaveForRequestedLeave = [];


// ---------------- END OF ADDED CODE -----------------------------------

// Added by :- Parineeta
// Date :- 01-02-2023
// Reason :- Using this method, we open All Leave page.

export default function AllLeave() {

  // Added by :- Parineeta
  // Date :- 20-1-2023
  // Reason :- get user leave details

  const [userLeaveDetails, setuserLeaveDetails] = useState([]);
  const [userTotalLeaveTypes, setUserTotalLeaveTypes] = useState([]);
  const [userLeavesApplied, setUserLeavesApplied] = useState([]);
  const [isManager, setIsManager] = useState("");
  const [userPermission, setUserPermission] = useState([]);
  const [totalRequestedLeave, setTotalRequestedLeave] = useState([]);
  const [allLeaveStatus, setallLeaveStatus] = useState([]);
  const [allTypeOfLeave, setAllTypeOfLeave] = useState([]);


  // Reason :- Using this map the data in My Leaves
  const [myLeave, setMyLeave] = useState(null);
  // End of added code

  // Reason :- Using this map the data in Requested Leaves
  const [filterLeave, setfilterLeave] = useState(null);
  // End of added code

  // Added by :- Parineeta
  // Date :- 08-02-2023
  // Reason :- when we first time come in leave page and if we reload the page that time used this.

  var [loader, setloader] = useState(false);
  useEffect(() => {
    setloader(true);
  }, []);

  // End of added code

  // Reason :- Using this method we will get the user leave details.

  const getUserLeaveDetails = async () => {
    const response = await getUserLeavesApi(
      window.localStorage.getItem("token")
    );
    if (response) {
      setloader(false);
      setuserLeaveDetails(response);
      setUserTotalLeaveTypes(response.totalUserLeaveType);
      setUserLeavesApplied(response.userLeaveApplied);
      setMyLeave(response.userLeaveApplied);
      setIsManager(response.isManager);
      setUserPermission(response.userPermission);
      setTotalRequestedLeave(response.totalRequestedLeave);
      setfilterLeave(response.totalRequestedLeave);
      setallLeaveStatus(response.allLeaveStatus);
      setAllTypeOfLeave(response.allTypeOfLeave)

      // Added by :- Parineeta
      // Date :- 30-03-2023
      // Reason :- In "My Leave" add the "all type of leave" in "checkMyLeaveCondition" with false conditions.

      typeOfLeave = response.totalUserLeaveType;
      for (var i = 0; i < typeOfLeave.length; i++) {
        checkMyLeaveCondition[typeOfLeave[i].type_of_leave] = false;
      }

      // End of added code 30-03-2023

      // Added by :- Parineeta
      // Date :- 30-03-2023
      // Reason :- In "Requested Leave" add the "all type of leave" in "checkRequestedLeaveCondition" with false conditions.

      typeOfLeaveForRequestedLeave = response.allTypeOfLeave;
      for (var i = 0; i < typeOfLeaveForRequestedLeave.length; i++) {
        checkRequestedLeaveCondition[typeOfLeaveForRequestedLeave[i].type_of_leave] = false;
      }

      // End of added code 30-03-2023

      // Added by :- Parineeta
      // Date :- 01-02-2023
      // Reason :-  In "Requested Leave" add the "All leave status" in "checkRequestedLeaveCondition" with false conditions.

      leaveStatus = response.allLeaveStatus;
      for (var i = 0; i < leaveStatus.length; i++) {
        checkRequestedLeaveCondition[leaveStatus[i].status] = false;
      }

      // End of added code

    }
  };

  // End of added code

  // Added by - Parineeta
  // Date - 24-02-2023
  // Reason - In type-of-leave remove the "Leave Without pay" leave.

  var totalLeavetypes = [];
  for (var i = 0; i < userTotalLeaveTypes.length; i++) {
    if (userTotalLeaveTypes[i].type_of_leave === "Leave Without Pay") {
    } else {
      totalLeavetypes.push(userTotalLeaveTypes[i]);
    }
  }

  // End of added code  24-02-2023

  // Added by :- Parineeta Gedam
  // Date :- 29-03-2023
  // Reason :- whatever "no of days" leave user have, using this I will "count" that no of days and "map" that "value" in "type of leave" "all block".

  var countDay = 0;
  for (var i = 0; i < userTotalLeaveTypes.length; i++) {
    countDay += userTotalLeaveTypes[i].day
  }

  // End of added code 29-03-2023 

  // Added by :- Parineeta
  // Date :- 25-1-2023
  // Reason :- when user/manager change the action(like cancel, approve) then the leave page again re-render for updating the status.

  const [renderAllLeave, setRenderAllLeave] = useState(false);

  useEffect(() => {
    getUserLeaveDetails();
  }, [renderAllLeave]);

  // End of added code 25-1-2023

  // --------------------------------- FILTER MY LEAVES REPORTS WHEN USER CLICK ON APPLY BUTTON -----------------------

  const { Option } = Select;

  // Commented by - Parineeta
  // Date :- 24-02-2023
  // Reason :- Before this functionality, done with radio group options now it's change to dropdown.

  // Added by - Parineeta
  // Date :- 03-02-2023
  // Reason :- In My Leaves, when user select any radio option then Using this method that option will be checked(true)

  // function updateMyLeaveCondition(e) {
  //   if (e.target.checked && e.target.value === "all") {
  //     setMyLeaveShowCalender(false);
  //     checkMyLeaveCondition.all = true;
  //     checkMyLeaveCondition.today = false;
  //     checkMyLeaveCondition.thisWeek = false;
  //     checkMyLeaveCondition.thisMonth = false;
  //     checkMyLeaveCondition.thisYear = false;
  //     checkMyLeaveCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "today") {
  //     setMyLeaveShowCalender(false);
  //     checkMyLeaveCondition.all = false;
  //     checkMyLeaveCondition.today = true;
  //     checkMyLeaveCondition.thisWeek = false;
  //     checkMyLeaveCondition.thisMonth = false;
  //     checkMyLeaveCondition.thisYear = false;
  //     checkMyLeaveCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "week") {
  //     setMyLeaveShowCalender(false);
  //     checkMyLeaveCondition.all = false;
  //     checkMyLeaveCondition.today = false;
  //     checkMyLeaveCondition.thisWeek = true;
  //     checkMyLeaveCondition.thisMonth = false;
  //     checkMyLeaveCondition.thisYear = false;
  //     checkMyLeaveCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "month") {
  //     setMyLeaveShowCalender(false);
  //     checkMyLeaveCondition.all = false;
  //     checkMyLeaveCondition.today = false;
  //     checkMyLeaveCondition.thisWeek = false;
  //     checkMyLeaveCondition.thisMonth = true;
  //     checkMyLeaveCondition.thisYear = false;
  //     checkMyLeaveCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "year") {
  //     setMyLeaveShowCalender(false);
  //     checkMyLeaveCondition.all = false;
  //     checkMyLeaveCondition.today = false;
  //     checkMyLeaveCondition.thisWeek = false;
  //     checkMyLeaveCondition.thisMonth = false;
  //     checkMyLeaveCondition.thisYear = true;
  //     checkMyLeaveCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "custom") {
  //     checkMyLeaveCondition.all = false;
  //     checkMyLeaveCondition.today = false;
  //     checkMyLeaveCondition.thisWeek = false;
  //     checkMyLeaveCondition.thisMonth = false;
  //     checkMyLeaveCondition.thisYear = false;
  //     checkMyLeaveCondition.custom = true;
  //   }
  // }
  // End of added code 03-02-2023
  // End of commented code 24-02-2023

  // Added by :- Parineeta
  // Date :- 30-1-2023
  // Reason :- In My Leaves, when user select the radio "custom" option, then for showing the calendar(FromDate and ToDate).
  const [showMyLeaveCalender, setMyLeaveShowCalender] = useState(false);
  // End of added code 30-1-2023

  // Reason :- if user click on custom option then show the calender if click on another options then hide the calender.
  function handleMyLeaveCustom(e) {
    setMyLeaveShowCalender(true);
  }
  // end of added code 30-1-2023

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date.
  const [disable, setDisable] = useState(true);
  // End of added code

  // Added by Parineeta
  // Date :- 17-2-2023
  // Reason :- when user select start date then using this useState we set the date for End Date.
  const [toDate, setToDate] = useState(new Date());
  // End of added code

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date and whatever startDate they select also endDate start's with that date.

  const handleStartDate = (e) => {
    document.getElementById("myLeaveEndDate").value = "";
    var startDate = document.getElementById("myLeaveStartDate").value;
    setToDate(startDate);
    setDisable(false);
  };

  const handleToDate = (e) => {
    var MyLeaveFromDate = document.getElementById("myLeaveStartDate").value;
    var MyLeaveToDate = document.getElementById("myLeaveEndDate").value;
    if (MyLeaveFromDate > MyLeaveToDate) {
      message.info({
        content: "Start Date and To Date should be entered",
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
    }
    handleMyLeaveFilterSubmit(e);
  };

  // End of added code 17-02-2023

  // Added by - Parineeta
  // Date :- 22-02-2023
  // Reason :- In My Leaves, when user select any dropdown option then Using this method that option will be checked(true)

  function updateMyLeaveCondition(e) {
    if (e === "all") {
      setMyLeaveShowCalender(false);
      checkMyLeaveCondition.all = true;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = false;
      // checkMyLeaveCondition.allLeave = false;

    }
    if (e === "today") {
      setMyLeaveShowCalender(false);
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = true;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = false;
      // checkMyLeaveCondition.allLeave = false;
    }
    if (e === "week") {
      setMyLeaveShowCalender(false);
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = true;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = false;
      // checkMyLeaveCondition.allLeave = false;
    }
    if (e === "month") {
      setMyLeaveShowCalender(false);
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = true;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = false;
      // checkMyLeaveCondition.allLeave = false;
    }
    if (e === "year") {
      setMyLeaveShowCalender(false);
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = true;
      checkMyLeaveCondition.custom = false;
      // checkMyLeaveCondition.allLeave = false;
    }
    if (e === "custom") {
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = true;
      // checkMyLeaveCondition.allLeave = false;
    }
    if (e === "allTypesOfLeave") {
      checkMyLeaveCondition.all = false;
      checkMyLeaveCondition.today = false;
      checkMyLeaveCondition.thisWeek = false;
      checkMyLeaveCondition.thisMonth = false;
      checkMyLeaveCondition.thisYear = false;
      checkMyLeaveCondition.custom = false;
      checkMyLeaveCondition.allLeave = false;
    }
  }
  // End of added code 22-02-2023

  // Added by - Parineeta
  // Date :- 01-03-2023
  // Reason :- In My Leaves, when user select any "Type of leave" (Type of leave like, Planed leave, Study Leave) then Using this method that option will be checked(true) and another will be false.

  function updateMyTypesOfLeavesCondition(e) {
    for (var i = 0; i < userTotalLeaveTypes.length; i++) {
      if (e == "allTypesOfLeave") {
        checkMyLeaveCondition["allLeave"] = true;
      }
      if (e !== "allTypesOfLeave") {
        checkMyLeaveCondition["allLeave"] = false;
      }

      if (e == userTotalLeaveTypes[i].type_of_leave) {
        checkMyLeaveCondition[userTotalLeaveTypes[i].type_of_leave] = true;
      }
      if (e !== userTotalLeaveTypes[i].type_of_leave) {
        checkMyLeaveCondition[userTotalLeaveTypes[i].type_of_leave] = false;
      }
    }
  }

  // End of added code 01-03-2023

  // Added by - Parineeta
  // Date :- 03-02-2023
  // Reason :- In My Leaves, when user select any option then we will get the filter leave data.

  const handleMyLeaveFilterSubmit = (e) => {
    var copyUserLeavesAppliedDetails = userLeavesApplied;

    if (checkMyLeaveCondition.all) {
      copyUserLeavesAppliedDetails = userLeavesApplied;
    }

    if (checkMyLeaveCondition.today) {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
        (dt) => dt.start_date === currentDate
      );
    }

    if (checkMyLeaveCondition.thisWeek) {
      var date = new Date();
      const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const offset = dt.getDate() - (dt.getDay() === 0 ? 6 : dt.getDay() - 1);
      var startofTheWeek = new Date(dt.setDate(offset));
      var endOfTheWeek = new Date();
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
        (dt) =>
          new Date(dt.start_date) >= startofTheWeek &&
          new Date(dt.start_date) <=
          new Date(endOfTheWeek.setDate(startofTheWeek.getDate() + 6))
      );
    }

    if (checkMyLeaveCondition.thisMonth) {
      var date = new Date();
      var startOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var startOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
        (dt) =>
          new Date(dt.start_date) >= startOfTheMonth &&
          new Date(dt.start_date) < startOfNextMonth
      );
    }

    if (checkMyLeaveCondition.thisYear) {
      var date = new Date();
      var startOfTheYear = new Date(date.getFullYear(), 0, 1);
      var startOfNextYear = new Date(date.getFullYear() + 1, 0, 1);
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
        (dt) =>
          new Date(dt.start_date) >= startOfTheYear &&
          new Date(dt.start_date) < startOfNextYear
      );
    }

    if (checkMyLeaveCondition.custom) {
      var canShowLeaveErrorMsg = false;
      var MyLeaveFromDate = document.getElementById("myLeaveStartDate").value;
      var MyLeaveToDate = document.getElementById("myLeaveEndDate").value;
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
        (dt) => {
          if (MyLeaveFromDate && MyLeaveToDate) {
            if (
              dt.start_date >= MyLeaveFromDate &&
              dt.start_date <= MyLeaveToDate
            ) {
              return dt;
            }
          } else {
            canShowLeaveErrorMsg = true;
            return dt;
          }
        }
      );
    }
    if (canShowLeaveErrorMsg === true) {
      message.info({
        content: "From Date and To Date should be entered",
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
    }

    // Added by :- Parineeta Gedam
    // Date :- 30-03-2023
    // Reason :- when user filter data by "Type of leave".

    if (checkMyLeaveCondition.allLeave) {
      copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails;
    }

    for (var i = 0; i < typeOfLeave.length; i++) {
      if (checkMyLeaveCondition[typeOfLeave[i].type_of_leave] === true) {
        copyUserLeavesAppliedDetails = copyUserLeavesAppliedDetails.filter(
          (dt) => dt.type_of_leave === typeOfLeave[i].type_of_leave
        );
      }
    }

    // End of added code 30-03-2023

    setMyLeave(copyUserLeavesAppliedDetails);
  };

  // End of added code 03-02-2023

  // ---------- FILTER REQUESTED LEAVES REPORTS WHEN USER CLICK ON APPLY BUTTON ---------

  // Commented by - Parineeta
  // Date :- 24-02-2023
  // Reason :- Before this functionality, done with radio group options now it's change to dropdown.

  // Added by - Parineeta
  // Date :- 02-02-2023
  // Reason :- In Requested Leaves, when user select any radio option then Using this method that option will be checked(true)

  // function updateRequestedLeaveCondition(e) {
  //   if (e.target.checked && e.target.value === "all1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = true;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "today1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = true;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "week1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = true;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "month1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = true;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "year1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = true;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "custom1") {
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = true;
  //     checkRequestedLeaveCondition.allStatus = false;
  //   }
  //   if (e.target.checked && e.target.value === "allStatus1") {
  //     setShowRequestedLeaveCalender(false);
  //     checkRequestedLeaveCondition.all = false;
  //     checkRequestedLeaveCondition.today = false;
  //     checkRequestedLeaveCondition.thisWeek = false;
  //     checkRequestedLeaveCondition.thisMonth = false;
  //     checkRequestedLeaveCondition.thisYear = false;
  //     checkRequestedLeaveCondition.custom = false;
  //     checkRequestedLeaveCondition.allStatus = true;
  //   }
  // }

  // Added by - Parineeta
  // Date :- 02-02-2023
  // Reason :- In Requested Leaves, when user select any radio option(status like, Pending, Approve, Cancel, Reject) then Using this method that option will be checked(true)

  // function updateRequestedLeaveStatusCondition(e) {
  //   for (var i = 0; i < allLeaveStatus.length; i++) {
  //     if (e.target.checked && e.target.value === allLeaveStatus[i].status) {
  //       checkRequestedLeaveCondition[allLeaveStatus[i].status] = true;
  //     }
  //     if (e.target.value !== allLeaveStatus[i].status) {
  //       checkRequestedLeaveCondition[allLeaveStatus[i].status] = false;
  //     }
  //   }
  // }
  // End of added code
  // End of commented code 25-02-2023

  // Added by :- Parineeta
  // Date :- 30-1-2023
  // Reason :- In Requested Leaves, when user select the radio "custom" option, then for showing the calendar(FromDate and ToDate)

  const [showRequestedLeaveCalender, setShowRequestedLeaveCalender] = useState(false);
  // End of added code 30-1-2023

  // Reason :- In Requested Leave if user click on custom option then show the calender if click on another options then hide the calender.
  function handleRequestedLeaveCustom(e) {
    setShowRequestedLeaveCalender(true);
  }
  // end of added code 30-1-2023

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date.
  const [requestedLeaveToDateDisable, setRequestedLeaveToDateDisable] = useState(true);
  // End of added code 17-02-2023

  // Added by Parineeta
  // Date :- 17-2-2023
  // Reason :- when user select start date then using this useState we set the date for End Date.
  const [requestedLeaveToDate, setRequestedLeaveToDate] = useState(new Date());
  // End of added code  17-2-2023

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date and whatever startDate they select also endDate start's with that date.

  const handleRequestedLeaveFromDate = (e) => {
    document.getElementById("requestedLeaveEndDate").value = "";
    var startDate = document.getElementById("requestedLeaveStartDate").value;
    setRequestedLeaveToDate(startDate);
    setRequestedLeaveToDateDisable(false);
  };

  const handleRequestedLeaveToDate = (e) => {
    var requestedLeaveFromDate = document.getElementById("requestedLeaveStartDate").value;
    var requestedLeaveToDate = document.getElementById("requestedLeaveEndDate").value;
    if (requestedLeaveFromDate > requestedLeaveToDate) {
      message.info({
        content: "Start Date and To Date should be entered",
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
    }
    handleRequestedLeaveSubmit(e);
  };

  // Added by - Parineeta
  // Date :- 25-02-2023
  // Reason :- In Requested Leaves, when user select any dropdown option then Using this method that option will be checked(true) and another option will be false.

  function updateRequestedLeaveCondition(e) {
    if (e === "all1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = true;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "today1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = true;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "week1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = true;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "month1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = true;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "year1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = true;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "custom1") {
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = true;
      checkRequestedLeaveCondition.allStatus = false;
    }
    if (e === "allStatus1") {
      setShowRequestedLeaveCalender(false);
      checkRequestedLeaveCondition.all = false;
      checkRequestedLeaveCondition.today = false;
      checkRequestedLeaveCondition.thisWeek = false;
      checkRequestedLeaveCondition.thisMonth = false;
      checkRequestedLeaveCondition.thisYear = false;
      checkRequestedLeaveCondition.custom = false;
      checkRequestedLeaveCondition.allStatus = true;
    }
  }

  // End of added code 25-02-2023

  // Added by - Parineeta
  // Date :- 01-03-2023
  // Reason :- In Requested Leaves, when user select any option(status like, Pending, Approve, Cancel, Reject) then Using this method that option will be checked(true) and another option will be false.

  function updateRequestedLeaveStatusCondition(e) {
    for (var i = 0; i < allLeaveStatus.length; i++) {
      if (e === allLeaveStatus[i].status) {
        checkRequestedLeaveCondition[allLeaveStatus[i].status] = true;
      }
      if (e !== allLeaveStatus[i].status) {
        checkRequestedLeaveCondition[allLeaveStatus[i].status] = false;
      }
    }
  }

  // End of added code 01-03-2023

  // Added by - Parineeta
  // Date :- 01-03-2023
  // Reason :- In Requested Leaves, when user select any option(Type of leave like, Plan leave, Marriage Leave, Study Leave) then Using this method that option will be checked(true) then another option will be false.

  function updateRequestedLeaveTypeOfLeaveCondition(e) {
    for (var i = 0; i < allTypeOfLeave.length; i++) {
      if (e == allTypeOfLeave[i].type_of_leave) {
        checkRequestedLeaveCondition[allTypeOfLeave[i].type_of_leave] = true;
      }
      if (e !== allTypeOfLeave[i].type_of_leave) {
        checkRequestedLeaveCondition[allTypeOfLeave[i].type_of_leave] = false;
      }
    }
  }

  // End of added code 01-03-2023

  // Added by - Parineeta Gedam
  // Date :- 01-03-2023
  // Reason - In Requested Leaves, when user select any option then we will get the filter leave data in requested leave table.

  const handleRequestedLeaveSubmit = () => {
    var copyTotalRequestedLeave = totalRequestedLeave;

    if (checkRequestedLeaveCondition.all) {
      copyTotalRequestedLeave = totalRequestedLeave;
    }

    if (checkRequestedLeaveCondition.today) {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
        (dt) => dt.start_date === currentDate
      );
    }

    if (checkRequestedLeaveCondition.thisWeek) {
      var date = new Date();
      const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const offset = dt.getDate() - (dt.getDay() === 0 ? 6 : dt.getDay() - 1);
      var startofTheWeek = new Date(dt.setDate(offset));
      var endOfTheWeek = new Date();
      copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
        (dt) =>
          new Date(dt.start_date) >= startofTheWeek &&
          new Date(dt.start_date) <=
          new Date(endOfTheWeek.setDate(startofTheWeek.getDate() + 6))
      );
    }

    if (checkRequestedLeaveCondition.thisMonth) {
      var date = new Date();
      var startOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var startOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
        (dt) =>
          new Date(dt.start_date) >= startOfTheMonth &&
          new Date(dt.start_date) < startOfNextMonth
      );
    }

    if (checkRequestedLeaveCondition.thisYear) {
      var date = new Date();
      var startOfTheYear = new Date(date.getFullYear(), 0, 1);
      var startOfNextYear = new Date(date.getFullYear() + 1, 0, 1);
      copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
        (dt) =>
          new Date(dt.start_date) >= startOfTheYear &&
          new Date(dt.start_date) < startOfNextYear
      );
    }

    if (checkRequestedLeaveCondition.custom) {
      var canShowLeaveErrorMsg = false;
      var FromDate = document.getElementById("requestedLeaveStartDate").value;
      var ToDate = document.getElementById("requestedLeaveEndDate").value;
      copyTotalRequestedLeave = copyTotalRequestedLeave.filter((dt) => {
        if (FromDate && ToDate) {
          if (dt.start_date >= FromDate && dt.start_date <= ToDate) {
            return dt;
          }
        } else {
          canShowLeaveErrorMsg = true;
          return dt;
        }
      });
    }
    if (canShowLeaveErrorMsg === true) {
      message.info({
        content: "From Date and To Date should be enter",
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
    }

    if (checkRequestedLeaveCondition.allStatus) {
      copyTotalRequestedLeave = totalRequestedLeave;
    }

    for (var i = 0; i < leaveStatus.length; i++) {
      if (checkRequestedLeaveCondition[leaveStatus[i].status] === true) {
        copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
          (dt) => dt.status === leaveStatus[i].status
        );
      }
    }

    // Added by :- Parineeta Gedam
    // Date :- 30-03-2023
    // Reason :- when user filter data,  by "Type of leave".

    if (checkRequestedLeaveCondition.allLeave2) {
      copyTotalRequestedLeave = totalRequestedLeave;
    }

    for (var i = 0; i < typeOfLeaveForRequestedLeave.length; i++) {
      if (checkRequestedLeaveCondition[typeOfLeaveForRequestedLeave[i].type_of_leave] === true) {
        copyTotalRequestedLeave = copyTotalRequestedLeave.filter(
          (dt) => dt.type_of_leave === typeOfLeaveForRequestedLeave[i].type_of_leave
        );
      }
    }

    // End of added code 30-03-2023

    setfilterLeave(copyTotalRequestedLeave);
  };

  // End of added code 01-02-2023

  return (
    <div
      className="all-leave-parent-container"
      style={{ border: "2px solid #f8f9fa", marginTop: "13vh" }}
    >
      <div className="main-heading">
        <h1 className="all-leave-heading">All Leave Details</h1>
      </div>

      {loader === false ? (
        <div>
          <Row className="type-of-leave-container" style={{ marginTop: "5px" }} value="allTypesOfLeave">

            {/* Added by - Parineeta Gedam
                Date :- 29-03-2023
                Reason :- In "Type of leave" show "All" in hardcode, and whatever "no of days" leave user have i will "count" this and shows in "days".
            */}

            <div
              className="type-leave-Data"
              id="allTypesOfLeave"
              name="allTypesOfLeave"
              style={{
                background:
                  i % 2 === 0
                    ? "var(--typeOfLeave-container-secondary-odd-backgroundColor)"
                    : "var(--typeOfLeave-container-secondary-even-backgroundColor)",
                border: checkMyLeaveCondition["allLeave"] === true ? "var(--timeOff-typeOfLeave-container-secondary-borderColor)" : "none"

              }}
              onClick={(e) => {
                // updateMyLeaveCondition("allTypesOfLeave")
                updateMyTypesOfLeavesCondition("allTypesOfLeave");
                handleMyLeaveFilterSubmit();
              }}
            >
              <div className="leave-type-image">
                <img
                  className="leave-type-image-img"
                  src={allImg1}
                  alt="no img"
                />
              </div>
              <div className="leave-type-data-data" value="allTypesOfLeave">
                <span className="leave-type-label" name="allTypesOfLeave">All</span>
                <h1 style={{ marginTop: "6%" }} className="leave-type-label">
                  {countDay}
                </h1>
              </div>
            </div>

            {/* End of added code 29-03-2023 */}

            {totalLeavetypes.map((item, i) => (
              <div
                className="type-leave-Data"
                id={item.type_of_leave}
                onClick={(e) => {
                  updateMyTypesOfLeavesCondition(item.type_of_leave);
                  handleMyLeaveFilterSubmit(item.type_of_leave);
                }}

                style={{
                  background:
                    i % 2 === 0
                      ? "var(--typeOfLeave-container-secondary-odd-backgroundColor)"
                      : "var(--typeOfLeave-container-secondary-even-backgroundColor)",
                  border: checkMyLeaveCondition[item.type_of_leave] == true ? "5px solid #b26adc" : "none"
                }}
              >
                <div className="leave-type-image">
                  <img
                    className="leave-type-image-img"
                    src={config.apiBaseURL + "/media/" + item.image}
                    alt="no img"
                  />
                </div>
                <div className="leave-type-data-data">
                  <span className="leave-type-label">{item.type_of_leave}</span>
                  <h1 style={{ marginTop: "6%" }} className="leave-type-label">
                    {item.day}
                  </h1>
                </div>
              </div>
            ))}
          </Row>

          <div>
            <div className="second-parentdiv">
              <div className="second-heading-div">
                <span className="second-heading">My Leaves</span>

                {/* Added by - Parineeta
                  Date - 24-02-2023 
                  Reason - show dropdown for filter by today, month, week, year.
                  */}
                <div className="my-leave-filter-section1">
                  <label className="dropdown-label-heading">Filter by :</label>
                  <Space wrap>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 100,
                      }}
                      className="select-dropdown"
                      onChange={(e) => {
                        if (e == "custom") {
                          updateMyLeaveCondition(e);
                          handleMyLeaveCustom(e);
                        } else {
                          updateMyLeaveCondition(e);
                          handleMyLeaveFilterSubmit();
                        }
                      }}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "today",
                          label: "Today",
                        },
                        {
                          value: "week",
                          label: "Week",
                        },
                        {
                          value: "month",
                          label: "Month",
                        },
                        {
                          value: "year",
                          label: "Year",
                        },
                        {
                          value: "custom",
                          label: "Custom",
                        },
                      ]}
                    />
                  </Space>
                </div>
                <div className="my-leave-filter-section2">
                  {showMyLeaveCalender === true && (
                    <div>
                      <span className="my-leave-from-date">From:&nbsp;</span>{" "}
                      <input
                        className="my-leave-calender-input"
                        id="myLeaveStartDate"
                        type="date"
                        name="dateFilter1"
                        onChange={(e) => handleStartDate(e)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span className="my-leave-from-date">To:&nbsp;</span>
                      <input
                        className="my-leave-calender-input"
                        id="myLeaveEndDate"
                        type="date"
                        name="dateFilter1"
                        min={toDate}
                        max="3000-08-15"
                        disabled={disable}
                        onChange={(e) => handleToDate(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* end of added code 24-02-2023  */}

              {/* Commented by :- Parineeta
                  Date : 24-02-2023
                  Reason : before filter by radio options, now this functionality change will dropdown options
              */}
              {/* <div className="my-leave-radio-group-container">
                <div className="my-leave-filter-section1">
                  <input
                    type="radio"
                    id="all"
                    name="dateFilter1"
                    value="all"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => updateMyLeaveCondition(e)}
                    defaultChecked
                  />
                  <label htmlFor="all" style={{ fontSize: "1.7rem" }}>
                    All
                  </label>
                  <input
                    type="radio"
                    id="today"
                    name="dateFilter1"
                    value="today"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => updateMyLeaveCondition(e)}
                  />
                  <label htmlFor="today" style={{ fontSize: "1.6rem" }}>
                    Today
                  </label>
                  <input
                    type="radio"
                    id="week"
                    name="dateFilter1"
                    value="week"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => updateMyLeaveCondition(e)}
                  />
                  <label htmlFor="week" style={{ fontSize: "1.7rem" }}>
                    This Week
                  </label>
                  <input
                    type="radio"
                    id="month"
                    name="dateFilter1"
                    value="month"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => updateMyLeaveCondition(e)}
                  />
                  <label htmlFor="month" style={{ fontSize: "1.7rem" }}>
                    This Month
                  </label>
                  <input
                    type="radio"
                    id="year"
                    name="dateFilter1"
                    value="year"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => updateMyLeaveCondition(e)}
                  />
                  <label htmlFor="year" style={{ fontSize: "1.7rem" }}>
                    This Year
                  </label>
                  <input
                    type="radio"
                    id="custom"
                    name="dateFilter1"
                    value="custom"
                    className="leave-radio"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => {
                      handleMyLeaveCustom(e.target);
                      updateMyLeaveCondition(e);
                    }}
                  />
                  <label
                    htmlFor="custom"
                    className="my-leave-radio-label"
                    // style={{ fontSize: "1.rem" }}
                  >
                    Custom
                  </label>
                </div>

                <div className="my-leave-filter-section2">
                  {showMyLeaveCalender === true && (
                    <div>
                      <span className="my-leave-from-date">From:&nbsp;</span>{" "}
                      <input
                        id="myLeaveStartDate"
                        type="date"
                        name="dateFilter1"
                        onChange={(e) => handleStartDate(e)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span className="my-leave-from-date">To:&nbsp;</span>
                      <input
                        id="myLeaveEndDate"
                        type="date"
                        name="dateFilter1"
                        min={toDate}
                        max="3000-08-15"
                        disabled={disable}
                      />
                    </div>
                  )}
                </div>
                <input
                  type="submit"
                  value="Filter"
                  className="my-leave-filter-btn"
                  onClick={handleMyLeaveFilterSubmit}
                />
              </div> */}

              {myLeave != null && myLeave.length > 0 ? (
                <>
                  <MyLeaveTabel
                    leave={myLeave}
                    permission={userPermission}
                    setRenderAllLeave={setRenderAllLeave}
                    renderAllLeave={renderAllLeave}
                    isManager={isManager}
                  />
                </>
              ) : (
                <h1 className="main-heading">No Leaves Applied</h1>
              )}
            </div>

            <br></br>

            {isManager === true && (
              <>
                <div className="requested-leave-parentdiv">
                  <div className="second-heading-div">
                    <span className="second-heading">Requested Leaves</span>

                    {/* Added by - Parineeta
                  Date - 24-02-2023 
                  Reason - show dropdown for filter by today, month, week, year.
                  */}
                    <div className="my-leave-filter-section1">
                      <label className="dropdown-label-heading">
                        Filter by :
                      </label>
                      <Space wrap>
                        <Select
                          defaultValue="All"
                          style={{
                            width: 100,
                          }}
                          className="select-dropdown"
                          onChange={(e) => {
                            if (e == "custom1") {
                              updateRequestedLeaveCondition(e);
                              handleRequestedLeaveCustom(e);
                            } else {
                              updateRequestedLeaveCondition(e);
                              handleRequestedLeaveSubmit();
                            }
                          }}
                          options={[
                            {
                              value: "all1",
                              label: "All",
                            },
                            {
                              value: "today1",
                              label: "Today",
                            },
                            {
                              value: "week1",
                              label: "Week",
                            },
                            {
                              value: "month1",
                              label: "Month",
                            },
                            {
                              value: "year1",
                              label: "Year",
                            },
                            {
                              value: "custom1",
                              label: "Custom",
                            },
                          ]}
                        />
                      </Space>
                    </div>

                    <div className="my-leave-filter-section3">
                      <label className="dropdown-label-heading">
                        Filter by status :
                      </label>
                      <Select
                        defaultValue="All"
                        style={{
                          width: 100,
                        }}
                        className="select-dropdown"
                        onChange={(e) => {
                          if (e == "custom1") {
                            updateRequestedLeaveStatusCondition(e);
                            handleRequestedLeaveSubmit(e);
                          } else {
                            updateRequestedLeaveStatusCondition(e);
                            handleRequestedLeaveSubmit();
                          }
                        }}
                      >
                        <Option value="allStatus1">All</Option>
                        {allLeaveStatus.map((leaveStatus) => (
                          <Option value={leaveStatus.status}>
                            {leaveStatus.status}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    {/* Added by :- Parineeta Gedam
                        Date :- 30-03-2023
                        Reason :- Data will be filter by Type of leave. 
                    */}
                    <div className="my-leave-filter-section4">
                      <label className="dropdown-label-heading">
                        Filter by Leave :
                      </label>
                      <Select
                        defaultValue="All"
                        style={{
                          width: 150,
                        }}
                        className="select-dropdown"
                        onChange={(e) => {
                          if (e == "custom1") {
                            updateRequestedLeaveTypeOfLeaveCondition(e);
                            handleRequestedLeaveSubmit(e);
                          } else {
                            updateRequestedLeaveTypeOfLeaveCondition(e);
                            handleRequestedLeaveSubmit();
                          }
                        }}
                      >
                        <Option value="allTypeOfLeave2">All</Option>
                        {allTypeOfLeave.map((leave) => (
                          <Option value={leave.type_of_leave}>
                            {leave.type_of_leave}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    {/* End of added code 30-03-2023 */}

                    <div className="my-leave-filter-section2">
                      {showRequestedLeaveCalender === true && (
                        <div>
                          <span className="my-leave-from-date">
                            From:&nbsp;
                          </span>
                          <input
                            className="my-leave-calender-input"
                            id="requestedLeaveStartDate"
                            type="date"
                            name="dateFilter"
                            onChange={(e) => handleRequestedLeaveFromDate(e)}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="my-leave-from-date">To:&nbsp;</span>
                          <input
                            className="my-leave-calender-input"
                            id="requestedLeaveEndDate"
                            type="date"
                            name="dateFilter"
                            min={requestedLeaveToDate}
                            max="3000-08-15"
                            disabled={requestedLeaveToDateDisable}
                            onChange={(e) => handleRequestedLeaveToDate(e)}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Commented by :- Parineeta
                  Date : 24-02-2023
                  Reason : before filter by radio options, now this functionality change will dropdown options
              */}
                  {/* <div className="requested-leave-radio-group-container">
                    <div className="requested-leave-filter-section1">
                      <input
                        type="radio"
                        id="all1"
                        name="dateFilter"
                        value="all1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => updateRequestedLeaveCondition(e)}
                        defaultChecked
                      />
                      <label htmlFor="all1" style={{ fontSize: "1.7rem" }}>
                        All
                      </label>
                      <input
                        type="radio"
                        id="today1"
                        name="dateFilter"
                        value="today1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => updateRequestedLeaveCondition(e)}
                      />
                      <label htmlFor="today1" style={{ fontSize: "1.7rem" }}>
                        Today
                      </label>
                      <input
                        type="radio"
                        id="week1"
                        name="dateFilter"
                        value="week1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => updateRequestedLeaveCondition(e)}
                      />
                      <label htmlFor="week1" style={{ fontSize: "1.7rem" }}>
                        This Week
                      </label>
                      <input
                        type="radio"
                        id="month1"
                        name="dateFilter"
                        value="month1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => updateRequestedLeaveCondition(e)}
                      />
                      <label htmlFor="month1" style={{ fontSize: "1.7rem" }}>
                        This Month
                      </label>
                      <input
                        type="radio"
                        id="year1"
                        name="dateFilter"
                        value="year1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => updateRequestedLeaveCondition(e)}
                      />

                      <label htmlFor="year1" style={{ fontSize: "1.7rem" }}>
                        This Year
                      </label>
                      <input
                        type="radio"
                        id="custom1"
                        name="dateFilter"
                        value="custom1"
                        className="leave-radio"
                        style={{ fontSize: "1.5rem" }}
                        onClick={(e) => {
                          handleRequestedLeaveCustom(e.target);
                          updateRequestedLeaveCondition(e);
                        }}
                      />

                      <label htmlFor="custom1" style={{ fontSize: "1.7rem" }}>
                        Custom
                      </label>
                    </div>
                    <div className="requested-leave-filter-section2">
                      {handleRequestedLeaveCustom(e.target); === true && (
                        <div style={{ marginLeft: "5%" }}>
                          <span className="my-leave-from-date">
                            From:&nbsp;
                          </span>
                          <input
                            id="requestedLeaveStartDate"
                            type="date"
                            name="dateFilter"
                            onChange={(e) => handleRequestedLeaveFromDate(e)}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          <span className="my-leave-from-date">To:&nbsp;</span>
                          <input
                            id="requestedLeaveEndDate"
                            type="date"
                            name="dateFilter"
                            min={requestedLeaveToDate}
                            max="3000-08-15"
                            disabled={requestedLeaveToDateDisable}
                          />
                        </div>
                      )}
                    </div>
                    <div className="requested-leave-filter-section3">
                      <input
                        type="radio"
                        id="allStatus1"
                        name="statusFilter"
                        value="allStatus1"
                        style={{ fontSize: "1.5rem" }}
                        className="leave-radio"
                        onClick={(e) => updateRequestedLeaveStatusCondition(e)}
                        defaultChecked
                      />

                      <label
                        htmlFor="allStatus1"
                        style={{ fontSize: "1.7rem" }}
                      >
                        All
                      </label>
                      {allLeaveStatus.map((leaveStatus) => (
                        <>
                          <input
                            type="radio"
                            id={leaveStatus.status}
                            name="statusFilter"
                            className="leave-radio"
                            style={{ fontSize: "1.5rem" }}
                            value={leaveStatus.status}
                            onClick={(e) =>
                              updateRequestedLeaveStatusCondition(e)
                            }
                          />
                          <label
                            htmlFor={leaveStatus.status}
                            style={{ fontSize: "1.7rem" }}
                          >
                            {leaveStatus.status}
                          </label>
                        </>
                      ))}
                    </div>
                    <input
                      className="requested-leave-filter-btn"
                      type="submit"
                      value="Filter"
                      onClick={handleRequestedLeaveSubmit}
                    />
                  </div> */}
                  {/* End of commented code 25-02-2023 */}

                  {filterLeave != null && filterLeave.length > 0 ? (
                    <RequestedLeaveTabel
                      requestedLeave={filterLeave}
                      managerPermission={userPermission}
                      setRenderAllLeave={setRenderAllLeave}
                      renderAllLeave={renderAllLeave}
                    />
                  ) : (
                    <h1 className="main-heading">No Requested Leaves</h1>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      <br></br>
      <br></br>
    </div>
  );
}
