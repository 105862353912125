// Added by -  Parineeta Gedam
// Date :- 10-03-2023
// Reason :- From this user can apply for leave.

import "./ApplyForLeaveForm.css";
import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getLeavesTypeApi, postLeaveDetailApi } from "../api/DynamicApi";

function ApplyForLeaveForm() {

  var [i, setI] = useState(0);
  const [inputFields, setInputFields] = useState([]);

  // Added by Parineeta
  // Date :- 19-03-2023
  // Reason :- 
  const [renderThisPage, setRenderThisPage] = useState(true);
  // End of added code 19-03-2023

  // Added by Parineeta
  // Date :- 20-1-2023
  // Reason :- after apply for leave we navigate to the "All Leave page".

  const navigate = useNavigate();

  // End of added code

  // Added by Parineeta
  // Date :- 20-1-2023
  // Reason :- Whatever user login and by admin whatever leave he/she have , Using this method we will getting the type_of_leave.

  const [leaveType, setLeaveType] = useState([]);
  useEffect(() => {
    getLeaveType();
  }, []);

  const getLeaveType = async () => {
    const response = await getLeavesTypeApi(
      window.localStorage.getItem("token")
    );
    if (response) {
      setLeaveType(response);
    }
  };
  // End of added code 20-01-2023

  // Added by Parineeta
  // Date :- 20-3-2023
  // Reason :- Using this, first time shows the start_date, end_date, half_day, no_of_days.

  //data that will come from backend
  var backendData = [];

  //in injex.js please comment <React.StrictMode>
  //it will set all the initial rows(forms) according to data coming from backend
  useEffect(() => {
    let itr = 0;
    let initialInputFields = [];
    if (backendData.length > 0) {
      backendData.forEach((e) => {
        itr++;
        initialInputFields.push({
          sno: itr,
          start_date: e.start_date,
          end_date: e.end_date,
          description: e.description,
          removeRow: "",
        });
      });
      setInputFields(...inputFields, initialInputFields);
      setI(itr);
    } else {
      setInputFields([
        ...inputFields,
        {
          sno: i,
          start_date: "",
          end_date: "",
          description: "",
          removeRow: "",
        },
      ]);
    }
  }, []);
  // End of added code 20-01-2023


  // Added by Parineeta
  // Date :- 20-3-2023
  // Reason :- When user apply for leave, then using this method we will post the data in backend.

  const handleFormSubmit = async (e) => {
    var applyForLeaveData = [];

    // Added by :- Parineeta Gedam
    // Date :- 20-3-2023
    // Reason :- Arrenge the multiple data in key value pair.
    for (var row in inputFields) {
      var form = document.getElementById("form" + inputFields[row].sno);
      var data = {
        start_date: form.start_date.value,
        end_date: form.end_date.value,
        half_day: form.half_day.checked,
        no_of_days: form.no_of_days.value,
      };
      applyForLeaveData.push(data);
      // End of added code 20-03-2023

      // Added by Parineeta
      // Date :- 24-03-2023
      // Reason :- for validations

      if (data.start_date === "") {
        document.getElementById("form" + inputFields[row].sno + "start_date").innerText = "Please enter start date";
        return false;
      } else {
        document.getElementById("form" + inputFields[row].sno + "start_date").innerText = "";
      }
      if (data.end_date === "") {
        document.getElementById("form" + inputFields[row].sno + "end_date").innerText = "Please enter end date";
        return false;
      } else {
        document.getElementById("form" + inputFields[row].sno + "end_date").innerText = "";
      }
      // End of added code 24-03-2023
    }

    // Added by :- Parineeta Gedam
    // Date :- 20-3-2023
    // Reason :- Arrenge the data in key value pair.
    var allTimeOffData = {
      rowDetails: applyForLeaveData,
      type_of_leave: document.getElementById("type_of_leave").value,
      description: document.getElementById("description").value,
      token: window.localStorage.getItem("token"),
    };
    // End of added code 20-03-2023

    // Added by :- Parineeta Gedam
    // Date :- 24-03-2023
    // Reason :- for validations
    if (allTimeOffData.type_of_leave === "") {
      document.getElementById("form" + i + "type_of_leave").innerText = "Please enter type of leave";
      return false;
    } else {
      document.getElementById("form" + i + "type_of_leave").innerText = "";
    }
    if (allTimeOffData.description === "") {
      document.getElementById("form" + i + "description").innerText = "Please enter description ";
      return false;
    } else {
      document.getElementById("form" + i + "description").innerText = "";
    }
    // End of added code 24-03-2023

    // Added by :- Parineeta Gedam
    // Date :- 20-03-2023
    // Reason :- All arranged key valve pair data send in the backend.

    const response = await postLeaveDetailApi(allTimeOffData);
    if (response["success"]) {
      message.success({
        content: response["success"],
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
      navigate("/all_leave");
    } else {
      message.error({
        content: response["error"],
        key: 1,
        className: "custom-class",
        style: {
          marginTop: "9vh",
        },
      });
    }
    // End of added code 20-03-2023
  };

  // End of added code 20-03-2023

  // Added by - Parineeta Gedam
  // Date :- 20-3-2023
  // Reason :- using that method, we generate multiple row.

  function handleAddRow(e) {
    setI(++i);
    setInputFields([
      ...inputFields,
      { sno: i, start_date: "", end_date: "", removeRow: "" },
    ]);
    setRenderThisPage(false);
    setRenderThisPage(true);
  }

  // End of added code 20-03-2023

  // Added by - Parineeta Gedam
  // Date :- 20-3-2023
  // Reason :- using that method, we deleted row.

  function handleDeleteRow(e) {
    let copiedInputFields = [];
    if (inputFields.length > 1) {
      inputFields.filter((node) => {
        var row = document.getElementById("form" + node.sno);
        if (row["removeRow"].checked) {
          // Added by:- Parineeta Gedam 
          // Date:- 31-03-2023
          // Reason:- when user click on delete button without select any checkbox then that message will be display.
          message.success({
            content: "Successfully deleted",
            key: 1,
            style: {
              marginTop: "9vh",
            },
          });
          // End of added code 31-03-2023
          return false;
        } else {
          // Added by:- Parineeta Gedam 
          // Date:- 31-03-2023
          // Reason:- when user click on delete button without select any checkbox then that message will be display.
          message.error({
            content: "Please select at least one checkbox",
            key: 1,
            style: {
              marginTop: "9vh",
            },
          });
          // End of added code 31-03-2023
          copiedInputFields.push(node);
          return true;
        }
      });
      setInputFields(copiedInputFields);
    }
  }
  // End of added code 20-03-2023

  // Added by - Parineeta Gedam
  // Date :- 24-03-2023
  // Reason :- when user delete any row then re-count the no of days and show in "count input box". 

  useEffect(() => {
    var countDay = 0;
    for (var i = 0; i < document.getElementsByClassName("no_of_days").length; i++) {
      countDay += parseFloat(document.getElementsByClassName("no_of_days")[i].value);
    }
    setCount(countDay);
  }, [inputFields])

  // End of added code 24-03-2023

  // Added by Parineeta
  // Date :- 20-1-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date and whatever startDate they select also endDate start's with that date.

  var startDate = new Date();
  const handleStartDate = (id) => {
    var form = document.getElementById(id);

    form["end_date"].value = "";
    form["no_of_days"].value = "";
    document.getElementById("count").value = "";

    var startDateForm = form["start_date"];
    startDate = startDateForm.value;
    form["end_date"].disabled = false;
    form["end_date"].min = startDate;
  };

  // End of added code 20-01-2023

  // Added by Parineeta
  // Date :- 24-03-2023
  // Reason :- using this, map the "total no of days".

  var [count, setCount] = useState(0);

  // End of added code

  // Added by :- Parineeta Gedam
  // Date :- 15-2-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date and whatever startDate they select also endDate start's with that date.

  const handleEndDate = (id) => {
    var form = document.getElementById(id);

    // Modified by :- Parineeta Gedam
    // Date :- 24-03-2023
    // Reason :- It counts, how many number of days the user is taking leave and also calculate the half day and show in "NO of days".
    var startDate = form["start_date"].value;
    var endDate = form["end_date"].value;
    var halfDay = form["half_day"].checked;
    if (halfDay == true) {
      const dateOne = new Date(startDate);
      const dateTwo = new Date(endDate);
      const time = Math.abs(dateTwo - dateOne);
      const days = Math.ceil(time / (1000 * 60 * 60 * 24) + 1);
      const totalNoOfDays = days / 2;
      form["no_of_days"].value = totalNoOfDays;
    } else {
      const dateOne = new Date(startDate);
      const dateTwo = new Date(endDate);
      const time = Math.abs(dateTwo - dateOne);
      const days = Math.ceil(time / (1000 * 60 * 60 * 24));
      const totalNoOfDays = days + 1;
      form["no_of_days"].value = totalNoOfDays;
    }
    // End of added code 24-03-2023

    // Added by :- Parineeta Gedam
    // Date :- 24-03-2023
    // Reason :- It counts, how many number of days the user is taking leave and shows in count.
    var countDay = 0;
    for (var i = 0; i < document.getElementsByClassName("no_of_days").length; i++) {
      countDay += parseFloat(document.getElementsByClassName("no_of_days")[i].value);
    }
    setCount(countDay);
    // End of added code 24-03-2023
  };
  // End of added code 20-01-2023

  return (
    <>
      {/* Added by - Parineeta Gedam
      Date :- 20-3-2023
      Reason :- Desiging of "Apply for leave page" */}

      <div className="time-off-colomn2" id={"app"}>
        {renderThisPage
          ? inputFields.map((data, i) => {
            return (
              <form id={"form" + data.sno} key={"form" + data.sno}>
                <div className="time-off-row-container" >
                  <div className="time-off-remove-container">
                    {inputFields.length > 1 ? (
                      <>
                        <h2 className="time-off-remove-label">Remove : </h2>
                        <input
                          type="checkbox"
                          name="removeRow"
                          className="time-off-remove-checkbox"
                        />
                      </>
                    ) : null}
                  </div>
                  <div className="time-off-colomn-container">
                    <h2 className="time-off-label">Start Date : </h2>
                    <div style={{ width: "100%" }}>
                      <input
                        className="time-off-inputbox"
                        name="start_date"
                        placeholder="Start Date"
                        type="date"
                        onChange={(e) => {
                          handleStartDate("form" + data.sno);
                        }}
                      />
                      <h4
                        name="start_date_error_msg"
                        id={"form" + data.sno + "start_date"}
                        className="time-off-errormsg"
                      ></h4>
                    </div>
                  </div>
                  <div className="time-off-colomn-container">
                    <h2 className="time-off-label">End Date : </h2>
                    <div style={{ width: "100%" }}>
                      <input
                        className="time-off-inputbox"
                        name="end_date"
                        placeholder="End Date"
                        type="date"
                        disabled
                        max="3000-08-15"
                        onChange={(e) => {
                          handleEndDate("form" + data.sno);
                        }}
                      />
                      <h4
                        name="start_date_error_msg"
                        id={"form" + data.sno + "end_date"}
                        className="time-off-errormsg"
                      ></h4>
                    </div>
                  </div>
                </div>

                <div className="time-off-row-container">
                  <div className="time-off-extra-container"></div>
                  <div className="time-off-colomn-container">
                    <h2 className="time-off-half-day-label">Half Day : </h2>
                    <input
                      className="time-off-half-day-checkbox"
                      name="half_day"
                      placeholder="half_day"
                      type="checkbox"
                      onChange={(e) => {
                        handleEndDate("form" + data.sno);
                      }}
                    />
                  </div>
                  <div className="time-off-colomn-container">
                    <h2 className="time-off-label">No of days : </h2>
                    <div style={{ width: "100%" }}>
                      <input
                        className="time-off-inputbox no_of_days"
                        name="no_of_days"
                        placeholder="No of days"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          })
          : null}

        <div className="time-off-row-container">
          <div className="time-off-extra-container"></div>
          <div className="time-off-colomn-container">
            <h2 className="time-off-label">Type of Leave : </h2>
            <div style={{ width: "100%" }}>
              <select
                name="type_of_leave"
                id="type_of_leave"
                className="time-off-inputbox"
              >
                <option value={""} selected hidden disabled>
                  Select an option
                </option>
                {leaveType.map((leave) => (
                  <>
                    <option>{leave.type_of_leave}</option>
                  </>
                ))}
              </select>
              <h4
                name="start_date_error_msg"
                id={"form" + i + "type_of_leave"}
                className="time-off-errormsg"
              ></h4>
            </div>
          </div>
          <div className="time-off-colomn-container">
            <h2 className="time-off-label">Description : </h2>
            <div style={{ width: "100%" }}>
              <input
                type="text"
                name="description"
                id="description"
                className="time-off-inputbox"
                placeholder="Description"
                maxLength="255"
              />
              <h4
                name="start_date_error_msg"
                id={"form" + i + "description"}
                className="time-off-errormsg"
              ></h4>
            </div>
          </div>
        </div>

        <div className="time-off-row-container">
          <div className="time-off-extra-container"></div>
          <div className="time-off-colomn-container">
            <label className="time-off-label">Count : </label>
            <div style={{ width: "100%" }}>
              <input
                type="number"
                value={count}
                className="time-off-inputbox"
                id="count"
                placeholder="Count"
              />
            </div>
          </div>
        </div>

        <div className="time-off-btn-parent-container">
          <input
            type="button"
            name="add_row"
            value="Add row"
            onClick={handleAddRow}
            className="time-off-button"
          />
          <input
            type="button"
            name="delete_row"
            value="Delete row"
            onClick={handleDeleteRow}
            className="time-off-button"
          />
          <input
            type="submit"
            name="submit"
            value="Submit"
            onClick={handleFormSubmit}
            className="time-off-button"
          />
        </div>
      </div>

      {/* End of added code 20-03-2023 */}
    </>
  );
}

export default ApplyForLeaveForm;

// End of added code 10-03-2023
