// Added by Parineeta
// date : 19-1-2023
// Reason : My Leave Table, here user can see our own leaves.

import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { updateLeaveApi } from "../api/DynamicApi";

export default function MyLeaveTabel({
  // Reason - As a props, we will get the variable from AllLeave.jsx

  // leave - Using this we will map the our all data.
  // permission - Using this we will map the in Action.
  // setRenderAllLeave,renderAllLeave - Using this, when user update our status(like cancel, approve) then page will be re-render.
  // isManager - using this, we check login user is manager or not.

  leave,
  permission,
  setRenderAllLeave,
  renderAllLeave,
  isManager,

  // End of added code
}) {
  // Added by - Parineeta
  // Date - 25-02-2023
  const { TextArea } = Input;
  // end of added code

  // Added by Parineeta
  // Date :- 30-1-2023
  // Reason :- In My Leave table, When user select any action(like cancel, approve), So Using this method we will update the status.

  const [statusUpdate, setStatusUpdate] = useState("");
  const statusChange = (e) => {
    setStatusUpdate(e);
  };
  // End of added code

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- if manager is login then manager can have only "Cancel" options.

  const [reason, setReason] = useState("");
  const handleReason = (e) => {
    setReason(e.target.value);
  };
  // End of added code

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- Show validations in reason validations.

  const [reasonValidationsMsg, setReasonValidationsMsg] = useState({
    showStatusMsg: false,
    showReasonMsg: false,
    rowId: null,
  });
  // End of added code

  // Added by Parineeta
  // Date :- 17-02-2023
  // Reason :- if manager is login then manager can have only "Cancel" options.

  var copyPermission = permission;
  var ManagerOwnLeavePermission = [];
  for (var i = 0; i < copyPermission.length; i++)
    if (copyPermission[i] === "Cancel") {
      ManagerOwnLeavePermission.push(copyPermission[i]);
    }
  // End of added code

  // Added by Parineeta
  // Date :- 30-1-2023
  // Reason :- In My Leave table, When user select any action(like cancel, approve), So Using this method we will update the status in backend.
  const LeaveUpdate = async (id) => {
    if (reason === "" && statusUpdate === "") {
      setReasonValidationsMsg({
        showStatusMsg: true,
        showReasonMsg: true,
        rowId: id,
      });
    }
    if (reason === "") {
      setReasonValidationsMsg({
        showReasonMsg: true,
        rowId: id,
      });
    }
    if (statusUpdate === "") {
      setReasonValidationsMsg({
        showStatusMsg: true,
        rowId: id,
      });
    }
    if (reason !== "" && statusUpdate !== "") {
      const data = {
        id: id,
        status: statusUpdate,
        reason: reason,
      };
      const response = await updateLeaveApi(
        data,
        window.localStorage.getItem("token")
      );
      if (response) {
        setReasonValidationsMsg({
          showStatusMsg: false,
          showReasonMsg: false,
          rowId: null,
        });
        setRenderAllLeave(!renderAllLeave);
      }
      setStatusUpdate("");
    }
  };

  // End of added code

  return (
    <>
      {/* Added by Parineeta
      date : 19-1-2023
      Reason : My Leave Table, here user and manager can see our own leaves. */}
      <div>
        <div id="parentDiv">
          <table>
            <thead>
              <tr>
                {/* Commented by - Parineeta
                    Date - 25-02-2023
                    Reason - login user, email and user name not show
                */}
                {/* <th scope="col">User</th>
                <th scope="col">Email</th> */}
                {/* end of commented code */}

                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>

                {/* Added by - Parineeta
                    Date - 25-02-2023
                    Reason - Change table heading, Reason to description. 
                */}

                <th scope="col">Day</th>

                {/* End of added code 25-02-2023 */}

                {/* Commented by - Parineeta
                    Date - 25-02-2023
                    Reason - Change table heading, Reason to Description
                */}

                {/* <th scope="col">Reason</th> */}

                {/* End of commented code 25-02-2023 */}

                {/* Added by - Parineeta
                    Date - 24-03-2023
                    Reason -  add no of days
                */}

                <th scope="col">No of days</th>

                {/* End of added code 24-03-2023 */}

                {/* Added by - Parineeta
                    Date - 25-02-2023
                    Reason - Change table heading, Reason to description. 
                */}

                <th scope="col">Description</th>

                {/* End of added code 25-02-2023 */}

                <th scope="col">Type of Leave</th>
                <th scope="col">Status</th>

                {/* Commented by - Parineeta
                    Date - 25-02-2023
                    Reason - Change table heading, Action to Chnage Action.
                */}

                {/* <th scope="col">Action</th> */}

                {/* End of commented code 25-02-2023 */}

                {/* Added by - Parineeta
                    Date - 25-02-2023
                    Reason - Change table heading, Reason to description. 
                */}

                <th scope="col">Change status</th>

                {/* End of added code 25-02-2023 */}

                {/* Added by - Parineeta
                    Date - 25-02-2023
                    Reason - if user cancel our leave then should give reason.
                */}

                <th scope="col">Reason</th>

                {/* End of added code 25-02-2023 */}

                <th scope="col">Action</th>
              </tr>
            </thead>
            {leave?.map((item, i) => (
              <tbody>
                <tr>
                  {/* Commented by - Parineeta
                    Date - 25-02-2023
                    Reason - login user, email and user name not show
                */}

                  {/* <td
                    data-label="user"
                    style={{
                      marginTop: "5px",
                      background: i % 2 === 0 ? "white" : "var(--table-row2-primary-backgroundColor)",
                    }}
                    > */}
                  {/* commented by:- Suman  Date:- 16/02/2023
                     Reason: due to replace Username field to Name field  */}

                  {/* {item.user_name} */}

                  {/* // End of commented code 16/02/2023 */}

                  {/* Modified by:- Suman  Date:- 16/02/2023
                     Reason :- Due to change Username field to Name field */}

                  {/* {item.name} */}

                  {/* End of modified code  16/02/2023 */}
                  {/* </td> */}
                  {/* end of commented code 25-02-2023 */}

                  {/* Commented by - Parineeta
                    Date - 25-02-2023
                    Reason - login user, email and user name not show
                */}
                  {/* <td
                    data-label="email"
                    style={{
                      marginTop: "5px",
                      background: i % 2 === 0 ? "white" : "var(--table-row2-primary-backgroundColor)",
                    }}
                    >
                    {item.email}
                  </td> */}
                  {/* end of commented code 25-02-2023 */}

                  <td
                    data-label="Start date"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.start_date
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>

                  <td
                    data-label="End date"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.end_date.slice(0, 10).split("-").reverse().join("-")}
                  </td>

                  {/* Added by - Parineeta Gedam
                      Date - 24-03-2023
                      Reason - add no of days
                  */}
                  <td
                    data-label="Half day"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.half_day === true ? <>Half day</> : <>Full Day</>}
                  </td>
                  {/* End of added code 24-03-2023 */}

                  {/* Added by - Parineeta Gedam
                      Date - 13-03-2023
                      Reason - add no of days
                  */}
                  <td
                    data-label="No of days"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.no_of_days}
                  </td>
                  {/* End of added code */}

                  <td
                    data-label="Description"
                    id="leave-description"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    <div className="leave-table-div"
                      style={{
                        float: "right",
                        textAlign: `${item.description.length > 60 ? "justify" : ""
                          }`,
                      }}>
                      {item.description}
                    </div>
                  </td>

                  <td
                    data-label="Type of leave"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.type_of_leave}
                  </td>

                  <td
                    data-label="Status"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {/* Modified by - Parineeta
                        Date - 18-03-2023
                        Reason - when user and manager update our status then show the updated status in past tense(like Approved, cancallled)
                    */}
                    {item.status === "Pending" ? (
                      <>{item.status}</>
                    ) : (
                      <>{item.alias}</>
                    )}
                    {/* End of modified code 18-03-2023 */}
                  </td>

                  {/* <td
                    data-label="action"
                    style={{
                      marginTop: "5px",
                      background: i % 2 === 0 ? "var(--table-row1-primary-backgroundColor)" : "var(--table-row2-primary-backgroundColor)",
                    }}
                    >
                    <select
                      className="status_dropdown"
                      onChange={(e) => statusChange(e.target.value)}
                    >
                      <option value={""} selected hidden disabled>
                        Select an option
                      </option>
                      {permission?.map((status, i) => (
                        <option value={status.status_id}>
                          {status.status_id}
                        </option>
                      ))}
                    </select>
                  </td> */}

                  {/* Added and modified by- Parineeta, 
                      Date- 16-02-2023 
                      Reason - if login user is manager and the status is pending then he /she can change the status (like cancel) then after shows (-).
                  */}

                  {isManager === true ? (
                    <>
                      {item.status === "Pending" ? (
                        <td
                          data-label="Change status"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          <select
                            className="status_dropdown"
                            onChange={(e) => {
                              statusChange(e.target.value);
                              setReasonValidationsMsg({
                                showStatusMsg: false,
                                showReasonMsg: false,
                                rowId: null,
                              });
                            }}
                          >
                            <option value={""} selected hidden disabled>
                              Select an option
                            </option>
                            {ManagerOwnLeavePermission?.map((status, i) => (
                              <option value={status}>{status}</option>
                            ))}
                          </select>
                          {(reasonValidationsMsg.showStatusMsg === true) &
                            (reasonValidationsMsg.rowId === item.id) ? (
                            <p className="leave-reason-error-msg" key={item.id}>
                              Please select status
                            </p>
                          ) : null}
                        </td>
                      ) : (
                        <td
                          data-label="Change status"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {/* Added by - Parineeta Gedam
                              Date - 18-03-2023
                              Reason - If status will updated then show Already changed.
                          */}
                          -
                          {/* End of Added code 18-03-2023 */}

                          {/* Commented by - Parineeta Gedam
                            Date - 18-03-2023
                            Reason - If status will updated then show Already changed.
                          */}
                          {/* {leave[i].alias} */}
                          {/* <select
                            disabled
                            className="status_dropdown"
                            defaultValue={leave[i]?.status}
                            >
                            <option value={leave[i].status}>{leave[i].status}</option>
                          </select> */}
                          {/* End of commented code 18-03-2023 */}
                        </td>
                      )}
                    </>
                  ) : (
                    <>
                      {item.status === "Pending" ? (
                        <td
                          data-label="Change status"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          <select
                            className="status_dropdown"
                            onChange={(e) => {
                              statusChange(e.target.value);
                              setReasonValidationsMsg({
                                showStatusMsg: false,
                                showReasonMsg: false,
                                rowId: null,
                              });
                            }}
                          >
                            <option value={""} selected hidden disabled>
                              Select an option
                            </option>
                            {permission?.map((status, i) => (
                              <option value={status}>{status}</option>
                            ))}
                          </select>
                          {(reasonValidationsMsg.showStatusMsg === true) &
                            (reasonValidationsMsg.rowId === item.id) ? (
                            <p className="leave-reason-error-msg" key={item.id}>
                              Please select status
                            </p>
                          ) : null}
                        </td>
                      ) : (
                        <td
                          data-label="Change status"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {/* Added by - Parineeta Gedam
                              Date - 18-03-2023
                              Reason - If status will updated then show Already changed.
                          */}
                          -
                          {/* End of Added code 18-03-2023*/}

                          {/* Commented by - Parineeta Gedam
                              Date - 18-03-2023
                              Reason - If status will updated then show Already changed.
                           */}
                          {/* {leave[i].alias} */}
                          {/* <select
                            disabled
                            className="status_dropdown"
                          >
                           {leave[i].status}
                          </select> */}
                          {/* End of commented code 18-03-2023 */}
                        </td>
                      )}
                    </>
                  )}

                  {/* End of added code */}

                  {/* Added by - Parineeta
                      Date - 25-02-2023
                      Reason - when user cancel our leave then should give the reason
                  */}

                  <td
                    data-label="Reason"
                    id="leave-reason"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "var(--table-row1-primary-backgroundColor)"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {item.status === "Pending" ? (
                      <>
                        <div className="reason-textarea" style={{
                          float: "right",
                          textAlign: `${item.description.length > 60 ? "justify" : ""
                            }`,
                        }}>
                          <TextArea
                            autoSize={true}
                            maxLength="255"
                            placeholder="Reason"
                            disabled={item.reason ? true : false}
                            style={{
                              backgroundColor: "white",
                              overflow: "auto",
                              maxHeight: "40px",
                              border: "1px solid #b2b2b2",
                            }}
                            defaultValue={item.reason}
                            onChange={(e) => handleReason(e)}
                            onKeyUp={() => {
                              setReasonValidationsMsg({
                                showReasonMsg: false,
                                showStatusMsg: false,
                                rowId: null,
                              });
                            }}
                          />
                          {(reasonValidationsMsg.showReasonMsg === true) &
                            (reasonValidationsMsg.rowId === item.id) ? (
                            <p className="leave-reason-error-msg" key={item.id}>
                              Please enter reason
                            </p>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="reason-textarea"
                          style={{
                            wordWrap: "break-word",
                            float: "right",
                            textAlign: `${item.description.length > 60 ? "justify" : ""
                              }`,
                          }}
                        >
                          {item.reason}
                        </div>
                      </>
                    )}
                  </td>

                  {/* End of added code 25-02-2023 */}

                  {/* Commented by - Parineeta Gedam
                      Date - 2-03-2023
                      Reason - before i used, save button, change into save icon
                  */}
                  {/* <td
                    data-label="save"
                    style={{
                      marginTop: "5px",
                      background: i % 2 === 0 ? "white" : "var(--table-row2-primary-backgroundColor)",
                    }}
                    >
                    {item.status === "Pending" ? (
                      <button
                        type="button"
                        className="requested-leave-tabel-save-btn"
                        onClick={(e) => LeaveUpdate(item.id)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="requested-leave-tabel-save-btn"
                        onClick={(e) => LeaveUpdate(item.id)}
                      >
                        Save
                      </button>
                    )}
                  </td> */}
                  {/* End of commented code 2-03-2023 */}

                  {/* Added by - Parineeta Gedam
                      Date - 2-03-2023
                      Reason - before i used save button, now change into save icon
                  */}
                  <td
                    data-label="Action"
                    style={{
                      marginTop: "5px",
                      background:
                        i % 2 === 0
                          ? "white"
                          : "var(--table-row2-primary-backgroundColor)",
                    }}
                  >
                    {/* Added by - Parineeta Gedam
                      Date - 24-03-2023
                      Reason - for every status we shows the different-different icons.
                    */}
                    <>
                      {item.status === "Pending" ? (
                        <div
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CheckCircleOutlined
                            style={{
                              backgroundColor: "grey",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      ) : null}
                      {item.status === "Approve" ? (
                        <div
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CheckCircleOutlined
                            style={{
                              backgroundColor: "#63ca30",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      ) : null}
                      {item.status === "Cancel" ? (
                        <div
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CloseCircleOutlined
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      ) : null}
                      {item.status === "Reject" ? (
                        <div
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CloseCircleOutlined
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                    {/* End of commented code 24-03-2023 */}

                    {/* Commented by - Parineeta Gedam
                      Date - 24-03-2023
                      Reason - change the icons
                    */}
                    {/* {item.status === "Pending" ? (
                        <div
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CheckCircleOutlined
                            style={{
                              backgroundColor: "grey",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          disabled
                          className="requested-leave-tabel-save-btn"
                          onClick={(e) => LeaveUpdate(item.id)}
                        >
                          <CheckCircleOutlined
                            style={{
                              backgroundColor: "#63ca30",
                              color: "white",
                              fontSize: "25px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      )} */}
                    {/* </> */}
                    {/* End of commented code 2-03-2023 */}

                    {/* <>
                      {item.status === "Approve" ? (
                        <>
                          <div
                            // disabled
                            className="requested-leave-tabel-save-btn"
                            onClick={(e) => LeaveUpdate(item.id)}
                          >
                            <CheckCircleOutlined
                              style={{
                                backgroundColor: "#63ca30",
                                color: "white",
                                fontSize: "25px",
                                borderRadius: "50px",
                              }}
                            />
                          </div>
                        </>
                      ) : null} */}
                    {/* </> */}
                  </td>
                  {/* End of added code 02-03-2023 */}
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
      {/* End of added code 19-1-2023 */}
    </>
  );
}
{/* End of added code 19-1-2023 */ }
