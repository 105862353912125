//  Added by Suman Date-25/01/2023
//  Reason - for see user work details

import { Select, Space } from "antd";
import React, { useState, useEffect } from "react";
import { SeeWorkDetailApi } from "../api/service";
import "./workSummary.css";

// Added by :- Suman sahu  Date :- 25-01-2023
// Reason :- Create a object for my work summary, we are storing conditions for filter logic.
var checkMyWorkCondition = {
  all: true,
  today: false,
  thisWeek: false,
  thisMonth: false,
  thisYear: false,
  custom: false,
};
// End of added code

// Added by:- Suman sahu  Date:- 27/03/2023
// Reason :- Create a object for teams work summary, we are storing conditions for filter logic.

var checkTeamsWorkCondition = {
  all: true,
  today: false,
  thisWeek: false,
  thisMonth: false,
  thisYear: false,
  custom: false,
  allPosition: false,
  allProject: false,
};
// End of added code

// Added by:- Suman sahu  Date:- 29/03/2023
// Reason:- For filter teams work data through position
var positionStatus;
// End of added code 29/03/2023

// Added by:- Suman sahu  Date:- 29/03/2023
// Reason:- For filter teams work data through project
var projectStatus;
// End of added code 29/03/2023

export default function WorkSummary() {
  // Reason:- For get all users work data
  const [workData, setWorkData] = useState([]);

  // Reason:- Show calander when manager click on custom option
  const [showUserWorkCalender, setShowUserWorkCalender] = useState(false);

  // Reason:- For filter manager work data
  const [filter, setFilter] = useState([]);

  // Reason:- For check, login user is manager or not
  const [isManager, setIsManager] = useState("");

  // Reason:- For get all manager work data
  const [usersData, setUsersData] = useState([]);

  // Reason:- For get all team members work data
  const [teamsWorkData, setTeamsWorkData] = useState([]);

  // Added by:- Suman sahu  Date:- 27/03/2023
  // Reason:- for filter teams work data
  const [teamsFilter, setTeamsFilter] = useState([]);
  // End of added code

  // Added by:- Suman sahu  Date:- 29/03/2023
  // Reason:- For get users all positions
  const [allPositionStatus, setAllPositionStatus] = useState([]);
  // End of added code 29/03/2023

  // Added by:- Suman sahu  Date:- 29/03/2023
  // Reason:- For get users all project
  const [allProjectStatus, setAllProjectStatus] = useState([]);
  // End of added code 29/03/2023

  useEffect(() => {
    showUsersWorkData();
  }, []);

  // Reason:- get all users work data on ui
  const showUsersWorkData = async () => {
    var token = localStorage.getItem("token");
    const response = await SeeWorkDetailApi(token);
    setWorkData(response);

    // Modified by:- Suman sahu  Date:- 13/03/2023
    // Reason :- allow manager can see work details of employees
    setUsersData(response.UsersWork);
    setFilter(response.UsersWork);
    setIsManager(response.isManager);
    setTeamsWorkData(response.TeamsWork);
    setTeamsFilter(response.TeamsWork);
    // End of added code

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason:- For get teams members all positions
    setAllPositionStatus(response.allPositionStatus);
    // End of added code 29/03/2023

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason :- add the position status used in teams work
    positionStatus = response.allPositionStatus;
    for (var i = 0; i < positionStatus.length; i++) {
      checkTeamsWorkCondition[positionStatus[i].position_name] = false;
    }
    // End of added code 29/03/2023

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason:- For get teams members all project
    setAllProjectStatus(response.allProjectStatus);
    // End of added code 29/03/2023

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason :- add the project status used in teams work
    projectStatus = response.allProjectStatus;
    for (var i = 0; i < projectStatus.length; i++) {
      checkTeamsWorkCondition[projectStatus[i].project_name] = false;
    }
    // End of added code 29/03/2023
  };

  const { Option } = Select;

  // Reason :- if user click on custom option then show the calender if click on another options then hide the calender.
  function handleCustomWork(e) {
    setShowUserWorkCalender(true);
  }

  // Reason :- In manager work, when user select any radio option then Using this method that option will be checked(true)

  // Commented by - Suman sahu
  // Date :- 18-03-2023
  // Reason :- Before this functionality, done with radio group options now it's change to dropdown.

  // function updateMyWorkCondition(e) {

  //   if (e.target.checked && e.target.value === "all") {
  //     setShowUserWorkCalender(false);
  //     checkMyWorkCondition.all = true;
  //     checkMyWorkCondition.today = false;
  //     checkMyWorkCondition.thisWeek = false;
  //     checkMyWorkCondition.thisMonth = false;
  //     checkMyWorkCondition.thisYear = false;
  //     checkMyWorkCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "today") {
  //     setShowUserWorkCalender(false);
  //     checkMyWorkCondition.all = false;
  //     checkMyWorkCondition.today = true;
  //     checkMyWorkCondition.thisWeek = false;
  //     checkMyWorkCondition.thisMonth = false;
  //     checkMyWorkCondition.thisYear = false;
  //     checkMyWorkCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "week") {
  //     setShowUserWorkCalender(false);
  //     checkMyWorkCondition.all = false;
  //     checkMyWorkCondition.today = false;
  //     checkMyWorkCondition.thisWeek = true;
  //     checkMyWorkCondition.thisMonth = false;
  //     checkMyWorkCondition.thisYear = false;
  //     checkMyWorkCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "month") {
  //     setShowUserWorkCalender(false);
  //     checkMyWorkCondition.all = false;
  //     checkMyWorkCondition.today = false;
  //     checkMyWorkCondition.thisWeek = false;
  //     checkMyWorkCondition.thisMonth = true;
  //     checkMyWorkCondition.thisYear = false;
  //     checkMyWorkCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "year") {
  //     setShowUserWorkCalender(false);
  //     checkMyWorkCondition.all = false;
  //     checkMyWorkCondition.today = false;
  //     checkMyWorkCondition.thisWeek = false;
  //     checkMyWorkCondition.thisMonth = false;
  //     checkMyWorkCondition.thisYear = true;
  //     checkMyWorkCondition.custom = false;
  //   }
  //   if (e.target.checked && e.target.value === "custom") {
  //     checkMyWorkCondition.all = false;
  //     checkMyWorkCondition.today = false;
  //     checkMyWorkCondition.thisWeek = false;
  //     checkMyWorkCondition.thisMonth = false;
  //     checkMyWorkCondition.thisYear = false;
  //     checkMyWorkCondition.custom = true;
  //   }

  // }
  // End of commented code
  // End of added code

  // Added by - Suman sahu
  // Date :- 18-03-2023
  // Reason :- In manager work, when user select any dropdown option then Using this method that option will be checked(true)

  function updateMyWorkCondition(e) {
    if (e === "all") {
      setShowUserWorkCalender(false);
      checkMyWorkCondition.all = true;
      checkMyWorkCondition.today = false;
      checkMyWorkCondition.thisWeek = false;
      checkMyWorkCondition.thisMonth = false;
      checkMyWorkCondition.thisYear = false;
      checkMyWorkCondition.custom = false;
    }
    if (e === "today") {
      setShowUserWorkCalender(false);
      checkMyWorkCondition.all = false;
      checkMyWorkCondition.today = true;
      checkMyWorkCondition.thisWeek = false;
      checkMyWorkCondition.thisMonth = false;
      checkMyWorkCondition.thisYear = false;
      checkMyWorkCondition.custom = false;
    }
    if (e === "week") {
      setShowUserWorkCalender(false);
      checkMyWorkCondition.all = false;
      checkMyWorkCondition.today = false;
      checkMyWorkCondition.thisWeek = true;
      checkMyWorkCondition.thisMonth = false;
      checkMyWorkCondition.thisYear = false;
      checkMyWorkCondition.custom = false;
    }
    if (e === "month") {
      setShowUserWorkCalender(false);
      checkMyWorkCondition.all = false;
      checkMyWorkCondition.today = false;
      checkMyWorkCondition.thisWeek = false;
      checkMyWorkCondition.thisMonth = true;
      checkMyWorkCondition.thisYear = false;
      checkMyWorkCondition.custom = false;
    }
    if (e === "year") {
      setShowUserWorkCalender(false);
      checkMyWorkCondition.all = false;
      checkMyWorkCondition.today = false;
      checkMyWorkCondition.thisWeek = false;
      checkMyWorkCondition.thisMonth = false;
      checkMyWorkCondition.thisYear = true;
      checkMyWorkCondition.custom = false;
    }
    if (e === "custom") {
      checkMyWorkCondition.all = false;
      checkMyWorkCondition.today = false;
      checkMyWorkCondition.thisWeek = false;
      checkMyWorkCondition.thisMonth = false;
      checkMyWorkCondition.thisYear = false;
      checkMyWorkCondition.custom = true;
    }
  }
  // End of added code

  // Added by:- Suman sahu  Date:- 25/01/2023
  // Reason:- when user click any radio button then click in filter button this function will be call

  const handleWorkFilterSubmit = () => {
    var copyUserWorkDetails = usersData;

    if (checkMyWorkCondition.all) {
      copyUserWorkDetails = usersData;
    }

    if (checkMyWorkCondition.today) {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      copyUserWorkDetails = copyUserWorkDetails.filter(
        (dt) => dt.date === currentDate
      );
    }

    if (checkMyWorkCondition.thisWeek) {
      var date = new Date();
      const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const offset = dt.getDate() - (dt.getDay() === 0 ? 6 : dt.getDay() - 1);
      var startofTheWeek = new Date(dt.setDate(offset));
      var endOfTheWeek = new Date();
      copyUserWorkDetails = copyUserWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startofTheWeek &&
          new Date(dt.date) <=
            new Date(endOfTheWeek.setDate(startofTheWeek.getDate() + 6))
      );
    }

    if (checkMyWorkCondition.thisMonth) {
      var date = new Date();
      var startOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var startOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      copyUserWorkDetails = copyUserWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startOfTheMonth &&
          new Date(dt.date) < startOfNextMonth
      );
    }

    if (checkMyWorkCondition.thisYear) {
      var date = new Date();
      var startOfTheYear = new Date(date.getFullYear(), 0, 1);
      var startOfNextYear = new Date(date.getFullYear() + 1, 0, 1);
      copyUserWorkDetails = copyUserWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startOfTheYear &&
          new Date(dt.date) < startOfNextYear
      );
    }

    if (checkMyWorkCondition.custom) {
      // var canShowLeaveErrorMsg = false;
      var MyWorkFromDate = document.getElementById("myWorkStartDate").value;
      var MyWorkToDate = document.getElementById("myWorkEndDate").value;
      copyUserWorkDetails = copyUserWorkDetails.filter((dt) => {
        if (MyWorkFromDate && MyWorkToDate) {
          if (dt.date >= MyWorkFromDate && dt.date <= MyWorkToDate) {
            return dt;
          }
        } else {
          // canShowLeaveErrorMsg = true;
          return dt;
        }
      });
    }

    setFilter(copyUserWorkDetails);
  };
  // End of added code

  // Added by:- Suman sahu  Date :- 19-03-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date and whatever startDate they select also endDate start's with that date.

  // Added by:- Suman sahu   Date :- 19-03-2023
  // Reason :- Till then, user not select the start date, he/she not select the end date.
  const [disable, setDisable] = useState(true);
  // End of added code

  // Added by:- Suman sahu   Date :- 19-03-2023
  // Reason :- when user select start date then using this useState we set the date for End Date.
  const [toDate, setToDate] = useState(new Date());
  // End of added code

  const handleStartDate = (e) => {
    document.getElementById("myWorkEndDate").value = "";
    var startDate = document.getElementById("myWorkStartDate").value;
    setToDate(startDate);
    setDisable(false);
  };

  const handleToDate = (e) => {
    handleWorkFilterSubmit(e);
  };

  // End of added code

  // ------------------ FILTER TEAMS WORK DETAILS, WHEN USER CLICK ON APPLY BUTTON ----------------------

  // Added by:- Suman sahu  Date:- 27/03/2023
  // Reason :- In Teams Work, if user click on custom option then show the calender
  const [showTeamsWorkCalender, setShowTeamsWorkCalender] = useState(false);
  // End of added code

  // Added by:- Suman sahu  Date:- 27/03/2023
  // Reason :- In Teams Work, if user click on custom option then show the calender if click on another options
  //            then hide the calender.

  function handleTeamsWorkCustom(e) {
    setShowTeamsWorkCalender(true);
  }
  // End of added code

  // Added by:- Suman sahu  Date:- 27/03/2023
  // Reason :- In teams work, when user select any dropdown option then Using this method that option will be checked(true)

  function updateTeamsWorkCondition(e) {
    if (e === "all1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = true;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }
    if (e === "today1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = true;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }
    if (e === "week1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = true;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }
    if (e === "month1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = true;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }
    if (e === "year1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = true;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }
    if (e === "custom1") {
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = true;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = false;
    }

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason:- For filter teams work data through position

    if (e === "allPosition1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = true;
      checkTeamsWorkCondition.allProject = false;
    }
    // End of added code 29/03/2023

    // Added by:- Suman sahu  Date:- 29/03/2023
    // Reason:- For filter teams work data through project

    if (e === "allProject1") {
      setShowTeamsWorkCalender(false);
      checkTeamsWorkCondition.all = false;
      checkTeamsWorkCondition.today = false;
      checkTeamsWorkCondition.thisWeek = false;
      checkTeamsWorkCondition.thisMonth = false;
      checkTeamsWorkCondition.thisYear = false;
      checkTeamsWorkCondition.custom = false;
      checkTeamsWorkCondition.allPosition = false;
      checkTeamsWorkCondition.allProject = true;
    }
    // End of added code 29/03/2023
  }
  // End of added code

  // Added by:- Suman sahu  Date:- 29/03/2023
  // Reason :- In Teams Work on "filter by position", when user select any option then Using this method that option will be checked(true)

  function updatePositonStatusCondition(e) {
    for (var i = 0; i < allPositionStatus.length; i++) {
      if (e === allPositionStatus[i].position) {
        checkTeamsWorkCondition[allPositionStatus[i].position] = true;
      }
      if (e !== allPositionStatus[i].position) {
        checkTeamsWorkCondition[allPositionStatus[i].position] = false;
      }
    }
  }

  // End of added code 29/03/2023

  // Added by:- Suman sahu  Date:- 29/03/2023
  // Reason :- In Teams Work on "filter by project", when user select any option then Using this method that option will be checked(true)

  function updateProjectStatusCondition(e) {
    for (var i = 0; i < allProjectStatus.length; i++) {
      if (e === allProjectStatus[i].project_name) {
        checkTeamsWorkCondition[allProjectStatus[i].project_name] = true;
      }
      if (e !== allProjectStatus[i].project_name) {
        checkTeamsWorkCondition[allProjectStatus[i].project_name] = false;
      }
    }
  }

  // End of added code 29/03/2023

  // Added by:- Suman sahu  Date:- 27/03/2023
  // Reason:- In teams work, when user click any radio button then click in filter button this function will be call

  const handleTeamsWorkFilterSubmit = () => {
    var copyTeamsWorkDetails = teamsWorkData;

    if (checkTeamsWorkCondition.all) {
      copyTeamsWorkDetails = teamsWorkData;
    }

    if (checkTeamsWorkCondition.today) {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
        (dt) => dt.date === currentDate
      );
    }

    if (checkTeamsWorkCondition.thisWeek) {
      var date = new Date();
      const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const offset = dt.getDate() - (dt.getDay() === 0 ? 6 : dt.getDay() - 1);
      var startofTheWeek = new Date(dt.setDate(offset));
      var endOfTheWeek = new Date();
      copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startofTheWeek &&
          new Date(dt.date) <=
            new Date(endOfTheWeek.setDate(startofTheWeek.getDate() + 6))
      );
    }

    if (checkTeamsWorkCondition.thisMonth) {
      var date = new Date();
      var startOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var startOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startOfTheMonth &&
          new Date(dt.date) < startOfNextMonth
      );
    }

    if (checkTeamsWorkCondition.thisYear) {
      var date = new Date();
      var startOfTheYear = new Date(date.getFullYear(), 0, 1);
      var startOfNextYear = new Date(date.getFullYear() + 1, 0, 1);
      copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
        (dt) =>
          new Date(dt.date) >= startOfTheYear &&
          new Date(dt.date) < startOfNextYear
      );
    }

    if (checkTeamsWorkCondition.custom) {
      var teamsWorkFromDate =
        document.getElementById("teamsWorkStartDate").value;
      var teamsWorkToDate = document.getElementById("teamsWorkEndDate").value;
      copyTeamsWorkDetails = copyTeamsWorkDetails.filter((dt) => {
        if (teamsWorkFromDate && teamsWorkToDate) {
          if (dt.date >= teamsWorkFromDate && dt.date <= teamsWorkToDate) {
            return dt;
          }
        } else {
          return dt;
        }
      });
    }

    // Added by:- Suman sahu  Date:- 27/03/2023
    // Reason:- In teams workon the "filter by position", when user click any radio button then click in filter button this function will be call
    if (checkTeamsWorkCondition.allPosition) {
      copyTeamsWorkDetails = teamsWorkData;
    }

    for (var i = 0; i < positionStatus.length; i++) {
      if (checkTeamsWorkCondition[positionStatus[i].position] === true) {
        copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
          (dt) => dt.position_name === positionStatus[i].position
        );
      }
    }
    // End of added code 29/03/2023

    // Added by:- Suman sahu  Date:- 27/03/2023
    // Reason:- In teams work on the "filter by project", when user click any radio button then click in filter button this function will be call
    if (checkTeamsWorkCondition.allProject) {
      copyTeamsWorkDetails = teamsWorkData;
    }

    for (var i = 0; i < projectStatus.length; i++) {
      if (checkTeamsWorkCondition[projectStatus[i].project_name] === true) {
        copyTeamsWorkDetails = copyTeamsWorkDetails.filter(
          (dt) => dt.project_name === projectStatus[i].project_name
        );
      }
    }
    // End of added code 29/03/2023

    setTeamsFilter(copyTeamsWorkDetails);
  };
  // End of added code

  // Added by:- Suman sahu   Date :- 27-03-2023
  // Reason :- In teams work, Till then, user not select the start date, he/she not select the end date.
  const [teamsDisable, setTeamsDisable] = useState(true);
  // End of added code

  // Added by:- Suman sahu   Date :- 27-03-2023
  // Reason :- In teams work, when user select start date then using this useState we set the date for End Date.

  const [teamstoDate, setTeamsToDate] = useState(new Date());
  // End of added code

  const handleTeamsWorkStartDate = (e) => {
    document.getElementById("teamsWorkEndDate").value = "";
    var startDate = document.getElementById("teamsWorkStartDate").value;
    setTeamsToDate(startDate);
    setTeamsDisable(false);
  };

  const handleTeamsWorkToDate = (e) => {
    handleTeamsWorkFilterSubmit(e);
  };
  // End of added code

  return (
    <>
      <div>
        {/* Modify by:- Suman sahu
          Date:- 03/02/2023
          Reason:- for style work summary page */}
        <div className="work-report-parentdiv">
          <div className="work_summary_heading">
            <h1>All Work Summary</h1>
          </div>

          <div className="worksummary_filter_div">
            {/* Added by - Suman sahu
                  Date - 18-03-2023 
                  Reason - show dropdown for filter by today, month, week, year.*/}
            <div>
              <div className="main_filter_work_div">
                <div className="work_second_heading_div">
                  <span className="my_work_heading">My Work</span>

                  <div className="my_work_filter_section1">
                    <label className="manager_dropdown_label_heading">
                      Filter by :
                    </label>
                    <Space wrap>
                      <Select
                        defaultValue="All"
                        style={{
                          width: 100,
                        }}
                        className="work_select_dropdown"
                        onChange={(e) => {
                          if (e == "custom") {
                            updateMyWorkCondition(e);
                            handleCustomWork(e);
                          } else {
                            updateMyWorkCondition(e);
                            handleWorkFilterSubmit();
                          }
                        }}
                        options={[
                          {
                            value: "all",
                            label: "All",
                          },
                          {
                            value: "today",
                            label: "Today",
                          },
                          {
                            value: "week",
                            label: "Week",
                          },
                          {
                            value: "month",
                            label: "Month",
                          },
                          {
                            value: "year",
                            label: "Year",
                          },
                          {
                            value: "custom",
                            label: "Custom",
                          },
                        ]}
                      />
                    </Space>
                  </div>
                  <div className="worksummary_section2">
                    {showUserWorkCalender === true && (
                      <div>
                        <span className="worksummary_from_date">
                          From:&nbsp;
                        </span>{" "}
                        <input
                          className="my_work_calender_input"
                          id="myWorkStartDate"
                          type="date"
                          name="fav_language"
                          onChange={(e) => handleStartDate(e)}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span className="worksummary_from_date">To:&nbsp;</span>
                        <input
                          className="my_work_calender_input"
                          id="myWorkEndDate"
                          type="date"
                          name="fav_language"
                          min={toDate}
                          disabled={disable}
                          onChange={(e) => handleToDate(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* End of added code */}

              {/* Commented by :- Suman sahu
                  Date : 18-03-2023
                  Reason : before filter by radio options, now this functionality change will dropdown options
              */}

              {/* <div className="worksummary_second_div">
                    <div className="worksummary_radio_group">
                      <div className="worksummary_section1">

                        <div className="worksummary_radio_all">
                          <input
                            type="radio"
                            id="all"
                            name="fav_language"
                            value="all"
                            className="worksummary_radio"
                            style={{ fontSize: "3rem" }}
                            onClick={(e) => updateMyWorkCondition(e)}
                            defaultChecked
                          />
                          <label htmlFor="all" className="worksummary_radio_label">
                            All
                          </label>
                        </div>

                        <div className="worksummary_radio_today">
                          <input
                            type="radio"
                            id="today"
                            name="fav_language"
                            value="today"
                            className="worksummary_radio"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => updateMyWorkCondition(e)}
                          />
                          <label htmlFor="today" className="worksummary_radio_label">
                            Today
                          </label>
                        </div>

                        <div className="worksummary_radio_thisweek">
                          <input
                            type="radio"
                            id="week"
                            name="fav_language"
                            value="week"
                            className="worksummary_radio"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => updateMyWorkCondition(e)}
                          />
                          <label htmlFor="week" className="worksummary_radio_label">
                            This Week
                          </label>
                        </div>

                        <div className="worksummary_radio_thismonth">
                          <input
                            type="radio"
                            id="month"
                            name="fav_language"
                            value="month"
                            className="worksummary_radio"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => updateMyWorkCondition(e)}
                          />
                          <label htmlFor="month" className="worksummary_radio_label">
                            This Month
                          </label>
                        </div>

                        <div className="worksummary_radio_thisyear">
                          <input
                            type="radio"
                            id="year"
                            name="fav_language"
                            value="year"
                            className="worksummary_radio"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => updateMyWorkCondition(e)}
                          />
                          <label htmlFor="year" className="worksummary_radio_label">
                            This Year
                          </label>
                        </div>

                        <div className="worksummary_radio_custom">
                          <input
                            type="radio"
                            id="custom"
                            name="fav_language"
                            value="custom"
                            className="worksummary_radio"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => {
                              handleCustomWork(e.target);
                              updateMyWorkCondition(e);
                            }}
                          />
                          <label htmlFor="custom" className="worksummary_radio_label">
                            Custom
                          </label>
                        </div>

                      </div>

                      <div className="worksummary_section2">
                        {showUserWorkCalender === true && (
                          <div>
                            <span className="worksummary_from_date">From:&nbsp;</span>{" "}
                            <input
                              id="myLeaveStartDate"
                              type="date"
                              name="fav_language"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            <span className="worksummary_from_date">To:&nbsp;</span>
                            <input
                              id="myLeaveEndDate"
                              type="date"
                              name="fav_language"
                            />
                          </div>
                        )}
                      </div>
                      <input
                        type="submit"
                        value="Filter"
                        className="worksummary_filter_btn"
                        onClick={handleWorkFilterSubmit}
                      />
                    </div>

                  </div> */}

              {/* End of commented code */}
            </div>
            {usersData != null && usersData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    {/* <th scope="col">User</th> */}
                    <th scope="col">Project</th>
                    <th scope="col">Task Id</th>
                    <th scope="col">Position</th>
                    <th scope="col">Date</th>
                    <th scope="col">Actual hour</th>
                    <th scope="col">Billable Hour</th>
                    <th scope="col">Work Type</th>
                    <th scope="col" className="description_column">
                      Description
                    </th>
                  </tr>
                </thead>
                {filter?.map((item, i) => (
                  <tbody>
                    <tr>
                      {/* Commented by:- Suman sahu  Date:- 17/03/2023
                        Reason:- Don't need to show user row on user work summary */}

                      {/* <td
                      data-label="user"
                      className="work_summary_data"
                      style={{
                        marginTop: "5px",
                        // display: "flex",
                        background: i % 2 === 0 ? "var(--table-row1-primary-backgroundColor)" : "var(--table-row2-primary-backgroundColor)",
                      }}
                    >
                      {item.user}
                    </td> */}

                      {/* End of added code */}

                      <td
                        data-label="Project"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {/* Modified by:- Suman Date:- 19/02/2023 
                        Reason:- due to fix issue on responsive this page */}
                        {item.project_name ? item.project_name : "other"}
                        {/* End of modified code */}
                      </td>

                      {/* Added by:- Suman sahu  Date:- 17/03/2023
                        Reason:-for show task_id data on user work summary*/}
                      <td
                        data-label="Task Id"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.task_id}
                      </td>
                      {/* End of added code */}

                      <td
                        data-label="Position"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.position_name}
                      </td>

                      <td
                        data-label="Date"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.date}
                      </td>

                      <td
                        data-label="Actual Hour"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.actual_hour}
                      </td>

                      <td
                        data-label="Billable Hour"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.billable_hour}
                      </td>

                      {/* Added by Suman Date-29/01/2023 */}
                      <td
                        data-label="Work Type"
                        className="work_summary_data"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        {item.work_type}
                      </td>

                      <td
                        data-label="Description"
                        className="work_summary_data"
                        id="work_summary_description"
                        style={{
                          marginTop: "5px",
                          background:
                            i % 2 === 0
                              ? "var(--table-row1-primary-backgroundColor)"
                              : "var(--table-row2-primary-backgroundColor)",
                        }}
                      >
                        <div
                          className="description_div"
                          style={{
                            float: "right",
                            // textOverflow:"ellipsis",whiteSpace:"nowrap", overflow: "hidden",
                            textAlign: `${
                              item.description.length > 60 ? "justify" : ""
                            }`,
                          }}
                        >
                          {item.description}
                        </div>
                      </td>

                      {/* End of modified code */}

                      {/* end of added code */}
                    </tr>
                  </tbody>
                ))}
              </table>
            ) : (
              <h1 className="main-heading">No Work</h1>
            )}
          </div>

          <br></br>

          {/* Added by:- Suman sahu  Date:- 13/03/2023
          Reason :- allow manager can see work details of employees */}
          {isManager === true && (
            <div className="worksummary_filter_div">
              <div className="worksummary_second_heading_div">
                <h2 className="teams_work_heading">Teams Work</h2>
                <div className="my_work_filter_section1">
                  <label className="manager_dropdown_label_heading">
                    Filter by :
                  </label>
                  <Space wrap>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 60,
                      }}
                      className="work_select_dropdown"
                      onChange={(e) => {
                        if (e == "custom1") {
                          updateTeamsWorkCondition(e);
                          handleTeamsWorkCustom(e);
                        } else {
                          updateTeamsWorkCondition(e);
                          handleTeamsWorkFilterSubmit();
                        }
                      }}
                      options={[
                        {
                          value: "all1",
                          label: "All",
                        },
                        {
                          value: "today1",
                          label: "Today",
                        },
                        {
                          value: "week1",
                          label: "Week",
                        },
                        {
                          value: "month1",
                          label: "Month",
                        },
                        {
                          value: "year1",
                          label: "Year",
                        },
                        {
                          value: "custom1",
                          label: "Custom",
                        },
                      ]}
                    />
                  </Space>
                </div>

                {/* Added by:- Suman sahu  Date:- 27/03/2023 
                    Reason:- For filter through positions*/}

                <div className="my_work_filter_section1">
                  <label className="manager_dropdown_label_heading">
                    Filter by position :
                  </label>
                  <Select
                    defaultValue="All"
                    style={{
                      width: 100,
                    }}
                    className="work_select_dropdown"
                    onChange={(e) => {
                      if (e == "custom1") {
                        updatePositonStatusCondition(e);
                        handleTeamsWorkFilterSubmit(e);
                      } else {
                        updatePositonStatusCondition(e);
                        handleTeamsWorkFilterSubmit();
                      }
                    }}
                  >
                    <Option value="allPosition1">All</Option>
                    {allPositionStatus.map((positionStatus) => (
                      <Option value={positionStatus.position}>
                        {positionStatus.position}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* End of added code 29/03/2023 */}

                {/* Added by:- Suman sahu  Date:- 27/03/2023 
                    Reason:- For filter through project*/}

                <div className="my_work_filter_section1">
                  <label className="manager_dropdown_label_heading">
                    Filter by project :
                  </label>
                  <Select
                    defaultValue="All"
                    style={{
                      width: 100,
                    }}
                    className="work_select_dropdown"
                    onChange={(e) => {
                      if (e == "custom1") {
                        updateProjectStatusCondition(e);
                        handleTeamsWorkFilterSubmit(e);
                      } else {
                        updateProjectStatusCondition(e);
                        handleTeamsWorkFilterSubmit();
                      }
                    }}
                  >
                    <Option value="allProject1">All</Option>
                    {allProjectStatus.map((projectStatus) => (
                      <Option value={projectStatus.project_name}>
                        {projectStatus.project_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* End of added code 29/03/2023 */}

                <div className="worksummary_section2">
                  {showTeamsWorkCalender === true && (
                    <div>
                      <span className="worksummary_from_date">From:&nbsp;</span>{" "}
                      <input
                        className="my_work_calender_input"
                        id="teamsWorkStartDate"
                        type="date"
                        name="fav_language"
                        onChange={(e) => handleTeamsWorkStartDate(e)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span className="worksummary_from_date">To:&nbsp;</span>
                      <input
                        className="my_work_calender_input"
                        id="teamsWorkEndDate"
                        type="date"
                        name="fav_language"
                        min={teamstoDate}
                        disabled={teamsDisable}
                        onChange={(e) => handleTeamsWorkToDate(e)}
                      />
                    </div>
                  )}
                </div>
              </div>

              {teamsWorkData != null && teamsWorkData.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Project</th>
                      <th scope="col">Task Id</th>
                      <th scope="col">Position</th>
                      <th scope="col">Date</th>
                      <th scope="col">Actual hour</th>
                      <th scope="col">Billable Hour</th>
                      <th scope="col">Work Type</th>
                      <th scope="col" className="description_column">
                        Description
                      </th>
                    </tr>
                  </thead>
                  {teamsFilter?.map((item, i) => (
                    <tbody>
                      <tr>
                        <td
                          data-label="User"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.user}
                        </td>

                        <td
                          data-label="Project"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.project_name ? item.project_name : "other"}
                        </td>

                        {/* Added by:- Suman sahu  Date:- 17/03/2023
                        Reason:-for show task_id data on teams work summary*/}
                        <td
                          data-label="Task Id"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.task_id}
                        </td>
                        {/* End of added code */}

                        <td
                          data-label="Position"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.position_name}
                        </td>

                        <td
                          data-label="Date"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.date}
                        </td>

                        <td
                          data-label="Actual Hour"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.actual_hour}
                        </td>

                        <td
                          data-label="Billable Hour"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.billable_hour}
                        </td>

                        <td
                          data-label="Work Type"
                          className="work_summary_data"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          {item.work_type}
                        </td>

                        <td
                          data-label="Description"
                          className="work_summary_data"
                          id="work_summary_description"
                          style={{
                            marginTop: "5px",
                            background:
                              i % 2 === 0
                                ? "var(--table-row1-primary-backgroundColor)"
                                : "var(--table-row2-primary-backgroundColor)",
                          }}
                        >
                          <div
                            className="description_div"
                            style={{
                              float: "right",
                              textAlign: `${
                                item.description.length > 60 ? "justify" : ""
                              }`,
                            }}
                          >
                            {item.description}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : (
                <h1 className="main-heading">No Teams Work</h1>
              )}
            </div>
          )}
          {/* End of added code */}
        </div>
      </div>
    </>
  );
}

// end of added code
