import React from "react";
import "./Home.css";
import { Card } from 'antd';
import img1 from '../assets/img.jfif';
import img2 from '../assets/bus.jpg';
import img3 from '../assets/1620332833.webp';
import banner from "../images/banner.jpg";


const About = () => {
  return (
    <>
      <div style={{ marginTop: "9rem", justifyContent: "center", width: "95%", marginBottom: "50px" }}>
        <div className="center" style={{ height: "1%" }}>
          <img
            style={{ height: "8%", width: "100%", borderRadius: "10px" }}
            src={banner} />
        </div>
        <div>
          <div className='about_parentdiv'>
            <div className="main_aboutus" >
              <Card hoverable className="card_about" >
                <>
                  <h2>Resource management</h2>
                  <p>Resource management is a series of processes and techniques used to ensure you have all the necessary resources to complete a project or meet business objectives. It also focuses on making the most efficient use of those resources by eliminating waste for more profits and a high return on investment (ROI). Resource management puts you in control to avoid conflicts as much as possible. </p>
                  <p>Resource management provides business stability by helping you get the most out of your resources. To maximize efficiency and to keep things running smoothly, you'll have to track how resources are used to help avoid overuse and underuse. As a result, you will gain control, profitability, and efficiency, among the main benefits of effective resource management.</p>
                </>
              </Card>
            </div>
          </div>
        </div>
        <div className="about_parentdiv" style={{ backgroundColor: 'white' }}>
          <div className='div_padding'>
            <Card >
              <h2 ><b> Our Services </b></h2>
              <hr />
              <div style={{ display: "flex", flexWrap: "wrap",justifyContent:"space-around", gap: "15px", rowGap: "10px",marginTop:"10px" }} >
                {/* <div className="service_card"> */}
                <Card className='serviceCards wrapContent' hoverable cover={<img alt=""style={{height:"30vh"}} src={img2} />}>

                  <h3> Resource Management </h3>
                  <hr />
                  <p> Manage resouces available to your firm effectively.
                    Set and track employee's goals and objectives.
                    Conduct performance reviews and evaluations.
                  </p>
                </Card>
                {/* </div> */}
                {/* <div className="service_card" > */}
                <Card className='serviceCards wrapContent' hoverable cover={<img alt="Can't load" src={img1} style={{height:"30vh"}}
                />}>
                  <h3> Leave Management </h3>
                  <hr />
                  <p>
                  Request and approve/reject leave requests.
                    View leave balances and history Schedule. </p>
                </Card>

                <Card className='serviceCards wrapContent' hoverable cover={<img alt="Can't load" src={img3} style={{height:"30vh"}}
                />}>
                  <h3> DSR Entry </h3>
                  <hr />
                  <p> Schedule and track employee training sessions.
                   Monitor progress and certifications. </p>
                </Card>

                {/* </div> */}
              </div>
            </Card>
            <br></br>
            <br></br>

          </div>
        </div>
      </div>
    </>
  );
};

export default About;
