import API from "./api";

//   Added by - Suman sahu
//   Reason - API for check, get user list on the registration page
export const UserDetailsApi = async () => {
  const response = await API.get("registered-user-list/", {
    headers: {},
  }).catch((err) => console.error("Failed"));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Suman sahu
//   Reason - API for post user details on the registration page
export const UserDetailsPostApi = async (data) => {
  const response = await API.post("registered-user/", data, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => console.error("Registration failed"));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Suman sahu
export const User = async () => {
  const response = await API.get("registered-user/", {
    headers: {},
  }).catch((err) => console.error("Failed"));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Suman sahu
//   Reason - API for post login user details on the login page
export const UserLoginPostApi = async (data) => {
  const response = await API.post("login-user/", data, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert(err));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Suman sahu
//   Reason - API for put user data after editing profile on the profile page
export const UpdateUserDetailApi = async (value) => {
  const response = await API.put(`update-profile/`, value, {
    headers: { "Content-Type": "multipart/form-data" },
  }).catch((err) => alert("regisration failed."));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Suman sahu   Date- 20/01/2023
//   Reason - API for get user position, project on the DSR page
export const GetUserProjectAndPositionApi = async (token) => {
  const response = await API.get(`get-user-work/${token}`, {
    headers: {},
  }).catch((err) => alert(err));
  return response ? response.data : {};
};

//  end of added code

//   Added by:- Suman sahu   Date- 05/03/2023
//   Reason - API for get user saved work on the DSR page
export const GetSavedWorkApi = async (token) => {
  const response = await API.get(`get-saved-works/${token}`, {
    headers: {},
  }).catch((err) => alert(err));
  return response ? response.data : {};
};

//  end of added code

// Added by:- Suman sahu  Date- 23/01/2023
// Reason - API for post user work detail on DSR

export const PostUserWorkApi = async (data, token) => {
  const response = await API.post(`post-user-work/${token}`, data, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert(err));
  return response ? response.data : {};
};
//  End of added code

// Added by:- Suman sahu  Date- 25/01/2023
// Reason - API for see user work detail on work summary ui
export const SeeWorkDetailApi = async (token) => {
  const response = await API.get(`get-work-detail/${token}`, {
    headers: {},
  }).catch((err) => alert(err));
  return response ? response.data : {};
};

//  End of added code

// Added by:- Suman sahu   Date:- 09/02/2023
// Reason:- API for sent OTP in the users email on the forget password

export const sendOtpMailApi = async (data) => {
  const response = await API.post(`send-email/`, data, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert(err));
  return response ? response.data : {};
};

// End of added code

// Added by:- Suman sahu   Date:- 09/02/2023
// Reason:- API for update password  on the forget password

export const updatePasswordApi = async (value) => {
  const response = await API.put(`update-password/`, value, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert(err));
  return response ? response.data : {};
};

// End of added code

//  Added by:- Suman  Data:- 24/03/2023
//  Reason:- API for delete selected row data on DSR form

export const DeleteRowApi = async (value) => {
  const response = await API.post(`delete-row/`, value, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert("Failed."));
  return response ? response.data : {};
};

// End of added code

// Added by:- Suman sahu  Date:- 02/04/2023
// Reaspn :- for submit saved work on the DSR
export const submitWorkDetailApi = async (value, token) => {
  const response = await API.put(`submit-dsr-work/${token}`, value, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => alert(err));
  return response ? response.data : {};
};
// End of added code
