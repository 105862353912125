import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import Context from './pages/Context';

// Added by: Suman  Date:- 10/02/2023
// Reason:-  for show eye icon on password and confirm password
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash, faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faEye, faEyeSlash, faCaretRight, faCaretLeft)
// End of added code

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Context>
            <App />
        </Context>
    // </React.StrictMode>

)