import API from "./api";

//   Added by - Parineeta
//   Date:- 20-1-2023
//   Reason - API for get leave type used in "Apply for leave page"

export const getLeavesTypeApi = async (token) => {
  const response = await API.get(`get-leaves-type/${token}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => console.error("failed"));
  return response ? response.data : null;
};
//  End of added code

//   Added by - Parineeta
//   Date:- 18-1-2023
//   Reason - API for post leave.
export const postLeaveDetailApi = async (form) => {
  const response = await API.post(`post-user-leave/`, form, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => console.error("failed."));
  return response ? response.data : {};
};
//  End of added code

//   Added by - Parineeta
//   Date:- 18-1-2023
//   Reason - get total leave details of login user

export const getUserLeavesApi = async (token) => {
  const response = await API.get(`get-user-leave-details/${token}`, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => console.error("failed"));
  return response ? response.data : null;
};
//  End of added code

//   Added by - Parineeta
//   Date:- 23-1-2023
//   Reason - Update leave used in "All leave page"
export const updateLeaveApi = async (value, token) => {
  const response = await API.put(`update-leave/${token}`, value, {
    headers: { "Content-Type": "application/json" },
  }).catch((err) => console.error("failed"));
  return response ? response.data : {};
};
//  End of added code
