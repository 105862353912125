import React from "react";
// import Header from './Header'

const Contact = () => {
  return (
    <>
      {/* <Header/> */}
      <div className="center" style={{ marginTop: "11rem", height: "80vh" }}>
        <h1>This is a Contact Page</h1>
      </div>
    </>
  );
};

export default Contact;
