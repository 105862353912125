// Added by Parineeta
// date : 20-02-2223
// Reason : for show loading

import { Space, Spin } from "antd";
import "./Spinner.css";
import adyant from "../images/adyantLogo.png";

const Spinner = () => {
  return (
    <div class="load-wrapp">
      {/* Added by Parineeta
      date : 25-03-2223
      Reason : for show loading */}

      {/* <div class="loading">
        <p>Adyant</p>
      </div> */}

      {/* End of commented code 25-03-2023 */}

      {/* Added by Parineeta
      date : 25-02-2223
      Reason : for show loading */}

      <div class="loading">
        <img src={adyant} className="header_adyant_logo" alt="adyant" />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Space size="middle">
          <Spin size="small" />
          <Spin />
          <Spin size="large" />
        </Space>
      </div>

      {/* End of commented code 25-03-2023 */}

    </div>
  );
};

export default Spinner;

// end of added code