import React from "react";
import registrationStyle from "./registration.module.css";
import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import Select from "react-select";
// import csc from "country-state-city";
import { UserDetailsPostApi } from "../api/service";
import adyant from "../images/adyant.png";
import "notyf/notyf.min.css";
import { message, Button, Form, Input, Radio } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function Registration() {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  // Reason:-  start code for country, state and city. where initial value of country will be fix (India)

  //Commented by:- Suman  Date:- 28/02/2023

  // const addressFromik = useFormik({

  //     initialValues: {
  //         country: "India",
  //         state: null,
  //         city: null
  //     },
  //     onSubmit: (values) => console.log(JSON.stringify(values))
  // });

  // const customStyles = {
  //     control: (base) => ({
  //         ...base,
  //         fontSize: "1.3rem",
  //         borderRadius: ".6rem",
  //         width: "90%"
  //     }),
  // }

  // const countries = csc.getAllCountries();
  // const { values, setFieldValue, setValues } = addressFromik;
  // useEffect(() => {

  //     setValues({ country: updatedCountries[100], state: '', city: '' }, false)

  // }, [])
  // useEffect(() => { }, [values]);

  // const updatedCountries = countries.map((country) => ({
  //     label: country.name,
  //     value: country.id,
  //     ...country
  // }));
  // const updatedStates = (countryId) =>
  //     csc
  //         .getStatesOfCountry(countryId)
  //         .map((state) => ({ label: state.name, value: state.id, ...state }));
  // const updatedCities = (stateId) =>
  //     csc
  //         .getCitiesOfState(stateId)
  //         .map((city) => ({ label: city.name, value: city.id, ...city }));

  // End of added code

  // Added by:-  Suman Date:- 24/02/2023
  // Reason :-  validation for date, user can enter only present and past date not a future
  var todaydate = new Date();
  var month = todaydate.getMonth() + 1;
  var year = todaydate.getUTCFullYear();
  var tdate = todaydate.getDate() - 1;
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  const maxDate = year + "-" + month + "-" + tdate;

  const handleFormSubmit = async (e) => {
    var user = {
      // profile_picture: e.profile_picture.files[0],
      name: e.name,
      phone_number: e.phone_number,
      email: e.email.toLowerCase(),
      password: e.password,
      confirm_password: e.confirm_password,
      date_of_birth: e.date_of_birth,
      gender: e.gender,

      // Commented by:- Suman  Date:- 28/02/2023

      // country: e.country.name,
      // state: e.state.name,
      // city: e.city.name,

      // End of commented code

      current_address: e.current_address,
      permanent_address: e.permanent_address,
    };

    const response = await UserDetailsPostApi(user);
    if (response["success"]) {
      var succesNotification = response["success"];
      message.success({
        content: succesNotification,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
      navigate("/");
    } else {
      // var errorNotification = response["error"]
      message.error({
        content: "This user is already exist",
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
    }
  };

  // Added by:- Suman sahu  Date:- 11/03/2023
  // Reason:- For show validation after focusout input field
  const focusOutInputError = async (currentElement) => {
    try {
      const values = await form.validateFields([currentElement]);
      //   console.log('Success:', values);
    } catch (errorInfo) {
      //   console.log('Failed:', errorInfo);
    }
  };
  // End of added code

  return (
    <>
      <div className={registrationStyle.registration_section}>
        <div className={registrationStyle.left_img_div} id="registrationImgBx">
          <img src={adyant} className={registrationStyle.img} alt="adyant" />
          {/* <i><h2 className={registrationStyle.imgContent}>"We Build, Quality Speaks"</h2></i> */}
          <i>
            <h3 className={registrationStyle.signin_content}>
              To use all features of the application Sign Up and discover a
              great amount of new opportunities !!!{" "}
            </h3>
          </i>
        </div>
        <div className={registrationStyle.center}>
          <div className={registrationStyle.form_div}>
            <Form
              form={form}
              className={registrationStyle.registration_form}
              onFinish={handleFormSubmit}
              {...formItemLayout}
            >
              <h1 className={registrationStyle.heading}>Sign Up</h1>

              {/* <input type="file" name='profile_picture' accept="image/*" hidden /> */}

              <div className={registrationStyle.reg_field}>
                <div className={registrationStyle.form_left}>
                  <Form.Item
                    name="name"
                    label="Name"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Name",
                      },
                      {
                        pattern: /^[a-zA-Z ]*$/,
                        message: "Enter only characters",
                      },
                    ]}
                  >
                    <Input
                      className={registrationStyle.registration_input_field}
                      placeholder="Name"
                      onBlur={() => {
                        focusOutInputError("name");
                      }}
                      // Added by:- Suman sahu  Date:- 27/03/2023
                      // Reason:- For disable paste events in this input field
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      // End of added code
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="E-mail"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    validateTrigger={["onBlur"]}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email ",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            if (
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:\.[a-zA-Z]+)*$/.test(
                                value
                              )
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Please enter valid e-mail")
                            );
                          }
                          // else {
                          //   return Promise.reject(
                          //     new Error("Please enter your email ")
                          //   );
                          // }
                        },
                      }),
                    ]}
                  >
                    <Input
                      className={registrationStyle.registration_input_field}
                      placeholder="Email"
                      onBlur={() => {
                        focusOutInputError("email");
                      }}
                      // Added by:- Suman sahu  Date:- 27/03/2023
                      // Reason:- For disable paste events in this input field
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      // End of added code
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (/^\S{1,}$/.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Password should contain only alphabets(a-z) or digits(0-9)"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className={registrationStyle.registration_input_field}
                      placeholder="Password"
                      onBlur={() => {
                        focusOutInputError("password");
                      }}
                      // Added by:- Suman sahu  Date:- 27/03/2023
                      // Reason:- For disable paste events in this input field
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      // End of added code
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Password and confirm password should be same"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className={registrationStyle.registration_input_field}
                      placeholder="Confirm password"
                      onBlur={() => {
                        focusOutInputError("confirm_password");
                      }}
                      // Added by:- Suman sahu  Date:- 27/03/2023
                      // Reason:- For disable paste events in this input field
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      // End of added code
                    />
                  </Form.Item>

                  <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    validateFirst={true}
                    rules={[
                      // Modified by:- Suman sahu  Date:- 26/03/2023
                      // Reason:- For fix issue on validation
                      // {
                      //   min: 10,
                      //   message: "Atleast 10 digits is required",
                      // },
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Enter only digits")
                            );
                          }
                          // else {
                          //   return Promise.reject(
                          //     new Error("Please enter your phone number")
                          //   );
                          // }
                        },
                      }),
                      // End of added code 26/03/2023
                    ]}
                  >
                    <Input
                      minLength={10}
                      maxLength={10}
                      className={registrationStyle.registration_input_field}
                      placeholder="Phone number"
                      onBlur={() => {
                        focusOutInputError("phone_number");
                      }}
                      // Added by:- Suman sahu  Date:- 27/03/2023
                      // Reason:- For disable paste events in this input field
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      // End of added code
                    />
                  </Form.Item>
                </div>

                <div className={registrationStyle.form_right}>
                  {/* Commented by:- Suman  Date:- 28/01/2023 */}

                  {/* <Form.Item
                      name="country"
                      label="Country" labelAlign='left'
                      rules={[{ required: true, message: 'Please select your country!' }]}
                  >
                      <Select id="country"
                          name="country"
                          styles={customStyles}
                          // defaultValue={updatedCountries[100]}
                          options={updatedCountries}
                          onChange={(value) => {
                              setValues({ country: value, state: null, city: null }, false);
                          }}>
                      </Select>
                  </Form.Item>

                  <Form.Item
                      name="state"
                      label="State" labelAlign='left'
                      rules={[{ required: true, message: 'Please select your state!' }]}
                  >
                      <Select id="state"
                          name="state"
                          styles={customStyles}
                          options={updatedStates(values.country ? values.country.value : null)}
                          value={values.state}
                          onChange={(value) => {
                              setFieldValue("state", value);
                          }}>
                      </Select>
                  </Form.Item>

                  <Form.Item
                      name="city"
                      label="City" labelAlign='left'
                      rules={[{ required: true, message: 'Please select your city!' }]}
                  >
                      <Select id="city"
                          name="city"
                          styles={customStyles}
                          label="City"
                          options={updatedCities(values.state ? values.state.value : null)}
                          value={values.city}
                          onChange={(value) => {
                              setFieldValue("city", value);
                          }}>
                      </Select>
                </Form.Item> */}

                  {/* End of added code */}

                  <Form.Item
                    style={{ margintTop: "3rem" }}
                    name="date_of_birth"
                    label="DOB"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    rules={[
                      {
                        required: true,
                        message: "Select your date of birth",
                      },
                    ]}
                  >
                    <input
                      type="date"
                      id="date_of_birth"
                      max={maxDate}
                      className={registrationStyle.registration_date_field}
                      onBlur={() => {
                        focusOutInputError("date_of_birth");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    id="gender"
                    className={registrationStyle.signup_label}
                    rules={[{ required: true, message: "Select gender" }]}
                  >
                    <Radio.Group>
                      <Radio value="Male">Male</Radio>
                      <Radio value="Female">Female</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="current_address"
                    label="Current address"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    rules={[
                      { required: true, message: "Enter your current address" },
                    ]}
                  >
                    <Input.TextArea
                      maxLength={255}
                      className={registrationStyle.registration_address_field}
                      placeholder="Current address"
                      onBlur={() => {
                        focusOutInputError("current_address");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="permanent_address"
                    label="Permanent address"
                    labelAlign="left"
                    className={registrationStyle.signup_label}
                    rules={[
                      {
                        required: true,
                        message: "Enter your permanent address",
                      },
                    ]}
                  >
                    <Input.TextArea
                      maxLength={255}
                      className={registrationStyle.registration_address_field}
                      placeholder="Permanent address"
                      onBlur={() => {
                        focusOutInputError("permanent_address");
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item
                className={registrationStyle.registration_button_div}
                htmlFor="sign_up"
              >
                <Button
                  htmlType="submit"
                  className={registrationStyle.signup_btn}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
