//   Added by - Suman

import React, { useState } from "react";
import profileStyle from "./profile.module.css";
import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import Select from "react-select";
// import csc from "country-state-city";
import { UpdateUserDetailApi } from "../api/service";
import { Appstate } from "./Context";
import "notyf/notyf.min.css";
import config from "../api/config";
import { message, Form, Input, Radio } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function Profile() {
  const [form] = Form.useForm();

  //  Added by :- Parineeta
  const { ProfileData } = Appstate();
  // end od added code

  // Reason:- render data after updated profile data
  const { setProfileData } = Appstate();
  // End of added code

  // Reason:- for navigate page

  const navigate = useNavigate();

  // Reason:-  start code for country, state and city. where initial value of country will be fix (India)

  // Commented by:- Suman  Date:- 28/02/2023

  // const addressFromik = useFormik({
  //     initialValues: {
  //         country: "India",
  //         state: null,
  //         city: null
  //     },
  //     onSubmit: (values) => console.log(JSON.stringify(values))
  // });

  // // Reason:- for ser background of select box

  // const customStyles = {
  //     control: (base) => ({
  //         ...base,
  //         fontSize: "1.3rem",
  //         borderRadius: ".6rem",
  //         width: "100%"
  //     }),
  // }

  // // End of added code

  // const countries = csc.getAllCountries();
  // const { values, handleSubmit, setFieldValue, setValues } = addressFromik;

  // useEffect(() => {
  //     setValues({ country: updatedCountries[100], state: '', city: '' }, false)
  // }, [])
  // useEffect(() => { }, [values]);

  // const updatedCountries = countries.map((country) => ({
  //     label: country.name,
  //     value: country.id,
  //     ...country
  // }));
  // const updatedStates = (countryId) =>
  //     csc
  //         .getStatesOfCountry(countryId)
  //         .map((state) => ({ label: state.name, value: state.id, ...state }));
  // const updatedCities = (stateId) =>
  //     csc
  //         .getCitiesOfState(stateId)
  //         .map((city) => ({ label: city.name, value: city.id, ...city }));

  // End of commented code

  // Reason :-  validation for date, user can enter only present and past date not a future
  var todaydate = new Date();
  var month = todaydate.getMonth() + 1;
  var year = todaydate.getUTCFullYear();
  var tdate = todaydate.getDate() - 1;
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  const maxDate = year + "-" + month + "-" + tdate;
  // End of added code

  // Reason:- when click on submit button this function will be call
  const handleFormSubmit = async (e) => {
    var user = {
      id: e.id,
      name: e.name,
      email: e.email,
      phone_number: e.phone_number,
      date_of_birth: e.date_of_birth,
      gender: e.gender,

      // Commented by:- Suman  Date:- 28/02/2023

      // country: e.country.name,
      // state: e.state.name,
      // city: e.city.name,
      // state: values.state.label,
      // city: values.city.label,

      // End of commented code

      current_address: e.current_address,
      permanent_address: e.permanent_address,

      profile_picture: document.getElementById("profile_picture").files[0],
    };

    const response = await UpdateUserDetailApi(user);
    if (response["msg"]) {
      var succesNotification = response["msg"];
      message.success({
        content: succesNotification,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });

      // Reason:- get & set profile data
      localStorage.setItem(
        "profileData",
        JSON.stringify(response["profileData"])
      );
      setProfileData(response["profileData"]);

      // Reason:- for navigate home page after edited and save profile

      // Modified by:- Suman sahu  Date:- 20/03/2023
      // Reason:- for navigate dashboard page after edited and save profile

      // navigate('/')
      navigate("/dashboard");

      // End of modified code
    } else {
      var errorNotification = response["error"];
      message.error({
        content: errorNotification,
        key: 1,
        style: {
          marginTop: "9vh",
        },
      });
    }
  };

  // Added by:- Suman sahu  Date:- 11/03/2023
  // Reason:- For show validation after focusout input field
  const focusOutInputError = async (currentElement) => {
    try {
      const values = await form.validateFields([currentElement]);
      //   console.log('Success:', values);
    } catch (errorInfo) {
      //   console.log('Failed:', errorInfo);
    }
  };
  // End of added code

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (document.getElementById("profile_picture").files[0]) {
      setPicture(document.getElementById("profile_picture").files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(document.getElementById("profile_picture").files[0]);
    }
  };

  return (
    <>
      <div className={profileStyle.profile_section}>
        <div className={profileStyle.center}>
          {ProfileData ? (
            <Form
              {...formItemLayout}
              onFinish={handleFormSubmit}
              form={form}
              encType="multipart-formdata"
            >
              <h1 className={profileStyle.profile_heading}>
                Edit Your Profile
              </h1>
              <div className={profileStyle.profile_field}>
                <div className={profileStyle.profile_img_field}>
                  <div className={profileStyle.profile_imgfield_setimg}>
                    <img
                      className={profileStyle.profile_img}
                      src={config.apiBaseURL + ProfileData.profile_picture}
                    />
                  </div>
                  <input
                    onChange={onChangePicture}
                    className={profileStyle.file_select_box}
                    type="file"
                    name="profile_picture"
                    id="profile_picture"
                    accept="image/*"
                  />
                  <br></br>
                  <label
                    htmlFor="profile_picture"
                    className={profileStyle.file_label_button}
                  >
                    Change Profile
                  </label>
                </div>
                <div className={profileStyle.profile_input_field_div}>
                  <div className={profileStyle.left_content}>
                    <Form.Item
                      initialValue={ProfileData.id}
                      name="id"
                      label="ID"
                      hidden={true}
                    >
                      <Input className={profileStyle.profile_input_box} />
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.name}
                      name="name"
                      label="Name"
                      className={profileStyle.profile_input_label}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Name!",
                        },
                        {
                          pattern: /^[a-zA-Z ]*$/,
                          message: "Please Enter only characters",
                        },
                      ]}
                    >
                      <Input
                        className={profileStyle.profile_input_box}
                        onBlur={() => {
                          focusOutInputError("name");
                        }}
                        // Added by:- Suman sahu  Date:- 27/03/2023
                        // Reason:- For disable paste events in this input field
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        // End of added code
                      />
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.email}
                      name="email"
                      label="E-mail"
                      className={profileStyle.profile_input_label}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        className={profileStyle.profile_input_box}
                        onBlur={() => {
                          focusOutInputError("email");
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.phone_number}
                      name="phone_number"
                      label="Phone Number"
                      className={profileStyle.profile_input_label}
                      validateTrigger={["onBlur"]}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Enter phone number!",
                        },
                        {
                          min: 10,
                          message: "Atleast 10 digits is required",
                        },
                        {
                          pattern: "^[0-9]*[.,]?[0-9]*$",
                          message: "Please enter only numbers",
                        },
                      ]}
                    >
                      <Input
                        maxLength={10}
                        className={profileStyle.profile_input_box}
                        onBlur={() => {
                          focusOutInputError("phone_number");
                        }}
                        // Added by:- Suman sahu  Date:- 27/03/2023
                        // Reason:- For disable paste events in this input field
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        // End of added code
                      />
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.date_of_birth}
                      name="date_of_birth"
                      label="DOB"
                      className={profileStyle.profile_input_label}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Select date of birth!",
                        },
                      ]}
                    >
                      <input
                        type="date"
                        id="date_of_birth"
                        max={maxDate}
                        className={profileStyle.profile_date_field}
                        onBlur={() => {
                          focusOutInputError("date_of_birth");
                        }}
                      />
                    </Form.Item>

                    {/* Commented by:- Suman  Date- 28/02/2023 */}

                    {/* <Form.Item
                      name="country"
                      label="Country"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        id="country"
                        name="country"
                        styles={customStyles}
                        options={updatedCountries}
                        onChange={(value) => {
                          setValues(
                            { country: value, state: null, city: null },
                            false
                          );
                        }}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name="state"
                      label="State"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please select your state!",
                        },
                      ]}
                    >
                      <Select
                        id="state"
                        name="State"
                        styles={customStyles}
                        options={updatedStates(
                          values.country ? values.country.value : null
                        )}
                        value={values.state}
                        onChange={(value) => {
                          setFieldValue("state", value);
                        }}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name="city"
                      label="City"
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Please select your city!" },
                      ]}
                    >
                      <Select
                        id="city"
                        name="city"
                        styles={customStyles}
                        label="City"
                        options={updatedCities(
                          values.state ? values.state.value : null
                        )}
                        value={values.city}
                        onChange={(value) => {
                          setFieldValue("city", value);
                        }}
                      ></Select>
                    </Form.Item> */}

                    {/* End of commented code */}
                  </div>

                  <div className={profileStyle.right_content}>
                    <Form.Item
                      initialValue={ProfileData.gender}
                      name="gender"
                      label="Gender"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      {/* Modified by:- Suman sahu  Date:- 19/03/2023
                          Reason:- For fix, post updated data */}
                      <Radio.Group>
                        {/* <input
                          className={profileStyle.gender_input}
                          type="radio"
                          name="gender"
                          id="Male"
                          value="Male"
                          defaultValue={ProfileData.gender}
                          defaultChecked={ProfileData.gender === "Male" && true}
                        />
                        <label
                          className={profileStyle.gender_label}
                          htmlFor="Male"
                          id="Male"
                        >
                          Male
                        </label>

                        <input
                          className={profileStyle.gender_input}
                          type="radio"
                          name="gender"
                          id="Female"
                          value="Female"
                          defaultValue={ProfileData.gender}
                          defaultChecked={
                            ProfileData.gender === "Female" && true
                          }
                        />
                        <label
                          className={profileStyle.gender_label}
                          htmlFor="Female"
                          id="Female"
                        >
                          Female
                        </label> */}

                        <Radio
                          value="Male"
                          defaultChecked={ProfileData.gender === "Male" && true}
                        >
                          Male
                        </Radio>
                        <Radio
                          value="Female"
                          defaultChecked={
                            ProfileData.gender === "Female" && true
                          }
                        >
                          Female
                        </Radio>
                      </Radio.Group>
                      {/* End of modified code */}
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.current_address}
                      name="current_address"
                      label="Current address"
                      className={profileStyle.profile_input_label}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Enter your current address!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        maxLength={255}
                        className={profileStyle.profile_input_box}
                        style={{ resize: "none", height: "7.5rem" }}
                        onBlur={() => {
                          focusOutInputError("current_address");
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      initialValue={ProfileData.permanent_address}
                      name="permanent_address"
                      label="Permanent address"
                      labelAlign="left"
                      className={profileStyle.profile_input_label}
                      rules={[
                        {
                          required: true,
                          message: "Enter your permanent address!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className={profileStyle.profile_input_box}
                        maxLength={255}
                        style={{ resize: "none", height: "7.5rem" }}
                        onBlur={() => {
                          focusOutInputError("permanent_address");
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className={profileStyle.profile_button_div}>
                <input
                  className={profileStyle.profile_btn}
                  type="submit"
                  value="Save"
                />
                <input
                  className={profileStyle.profile_btn}
                  type="button"
                  value="Cancel"
                  onClick={() => navigate("/")}
                />
              </div>
            </Form>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Profile;

// end of added code
