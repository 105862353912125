import React from "react";

const About = () => {
  return (
    <>
      <div className="center" style={{ marginTop: "11rem", height: "80vh" }}>
        <h1>This is a About Page</h1>
      </div>
    </>
  );
};

export default About;
